import { validateCommentStreamWidgetConfig, WidgetType } from "api-shared";
import { useRef } from "react";
import CommentStreamInfiniteList from "./CommentStreamInfiniteList";
import CommentStreamWidgetConfigForm from "./CommentStreamWidgetConfigForm";
import { IWidgetContentProps } from "./Widget";
import WidgetConfigDialog from "./WidgetConfigDialog";

const CommentStreamWidget = ({
    widget,
    isConfigDialogOpen,
    onConfigDialogClose,
    onConfigSave,
    translate,
    disabled = false,
    readOnlyLabel,
    isInView,
}: IWidgetContentProps) => {
    const rootRef = useRef<HTMLDivElement>(null);

    if (!validateCommentStreamWidgetConfig(widget.config)) {
        return null;
    }

    return (
        <>
            <WidgetConfigDialog<WidgetType.CommentStream>
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={onConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateCommentStreamWidgetConfig}
                FormComponent={CommentStreamWidgetConfigForm}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
            />
            <CommentStreamInfiniteList rootRef={rootRef} config={widget.config} enableQueries={isInView} />
        </>
    );
};

export default CommentStreamWidget;
