import AddIcon from "@mui/icons-material/AddRounded";
import { Button, Stack, styled, Typography } from "@mui/material";
import { MaxYear, MeasureCalculationGranularity, MinYear, SingleFieldValueTargets } from "api-shared";
import { TFunction } from "i18next";
import { omit } from "lodash";
import moment from "moment";
import { Fragment } from "react";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import CalculationTimeSelect from "../../../../components/input/date/CalculationTimeSelect";
import { SimpleCurrencyInput } from "../../../../components/input/SimpleCurrencyInput";
import SmallIconButton from "../../../../components/SmallIconButton";
import { useClientFiscalYear } from "../../../../domain/client";
import { calendarToFiscal, fiscalToCalendar } from "../../../../lib/fiscal-units";
import { translationKeys } from "../../../../translations/main-translations";
import WidgetConfigTabContainer from "../../WidgetConfigTabContainer";

const TargetsGrid = styled("div")(({ theme }) => ({
    display: "grid",
    gap: theme.spacing(1),
    //fixed value for fieldselect to stop overflow
    gridTemplateColumns: "1fr 1fr auto",
    alignItems: "center",
}));

const NoTargetsContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(1, 0),
}));

type LegacyProjectProgressConfigTabTargetsProps = {
    targets: SingleFieldValueTargets;
    onChange: (targets: SingleFieldValueTargets) => void;
    translate: TFunction;
    disabled?: boolean;
};

const LegacyProjectProgressConfigTabTargets = ({ targets, onChange, translate, disabled }: LegacyProjectProgressConfigTabTargetsProps) => {
    const fiscalYearStart = useClientFiscalYear();

    function handleAddFiscalYearTarget(hasMaxFiscalYear: boolean): void {
        const newTargetFields = { ...targets };
        const yearsSorted = Object.keys(newTargetFields).toSorted((a, b) => a.localeCompare(b));
        const baseYearMoment = moment(yearsSorted[hasMaxFiscalYear ? 0 : yearsSorted.length - 1]);
        const newFiscalYearMoment = calendarToFiscal(
            baseYearMoment,
            fiscalYearStart,
            MeasureCalculationGranularity.FISCAL_YEAR,
        ).fiscalMoment;
        if (yearsSorted.length > 0) {
            if (hasMaxFiscalYear) {
                newFiscalYearMoment.subtract(1, "year");
            } else {
                newFiscalYearMoment.add(1, "year");
            }
        }
        newFiscalYearMoment.add(fiscalYearStart, "month");
        const newFiscalYear = newFiscalYearMoment.format("YYYY-MM-DD");
        newTargetFields[newFiscalYear] = null;
        onChange(newTargetFields);
    }

    function handleUpdateFiscalYear(previousFiscalYear: string, newFiscalYearRange: moment.Moment, target: number | null): void {
        let newTargetFields = { ...targets };
        const newFiscalYearMoment = calendarToFiscal(
            newFiscalYearRange,
            fiscalYearStart,
            MeasureCalculationGranularity.FISCAL_YEAR,
        ).fiscalMoment;
        newFiscalYearMoment.add(fiscalYearStart, "month");
        const newFiscalYear = newFiscalYearMoment.format("YYYY-MM-DD");
        newTargetFields[newFiscalYear] = target;
        newTargetFields = omit(newTargetFields, [previousFiscalYear]);
        onChange(newTargetFields);
    }

    function handleUpdateFiscalYearTarget(fiscalYear: string, target: number | null): void {
        const newTargetFields = { ...targets };
        newTargetFields[fiscalYear] = target;
        onChange(newTargetFields);
    }

    function handleRemoveFiscalYearTarget(field: string): void {
        const newTargetFields = omit(targets, [field]);
        onChange(newTargetFields);
    }

    const fiscalYearMonthDigits = `${1 + fiscalYearStart < 9 ? "0" + (fiscalYearStart + 1) : fiscalYearStart + 1}`;

    const hasMinFiscalYear = `${fiscalYearStart > 0 ? MinYear - 1 : MinYear}-${fiscalYearMonthDigits}-01` in targets;
    const hasMaxFiscalYear = `${fiscalYearStart > 0 ? MaxYear - 1 : MaxYear}-${fiscalYearMonthDigits}-01` in targets;

    return (
        <WidgetConfigTabContainer>
            <Stack spacing={1}>
                <Typography variant="body1" component="legend" fontWeight="medium">
                    {translate(translationKeys.VDLANG_DASHBOARDS_LEGACY_PROJECT_PROGRESS_TARGETS_FORM_TARGETS)}
                </Typography>
                {Object.keys(targets).length !== 0 ? (
                    <TargetsGrid>
                        {Object.entries(targets)
                            .toSorted(([a], [b]) => a.localeCompare(b))
                            .map(([fiscalYear, target]) => (
                                <Fragment key={fiscalYear}>
                                    <CalculationTimeSelect
                                        value={moment(fiscalToCalendar(moment(fiscalYear), fiscalYearStart).toDate())}
                                        onChange={(timeFrame) => {
                                            if (timeFrame !== null) {
                                                handleUpdateFiscalYear(fiscalYear, timeFrame, target);
                                            }
                                        }}
                                        translate={translate}
                                        fiscalYearStart={fiscalYearStart}
                                        disabled={disabled}
                                        granularity={MeasureCalculationGranularity.FISCAL_YEAR}
                                        min={
                                            calendarToFiscal(
                                                moment(`${MinYear}-${fiscalYearMonthDigits}-01`),
                                                fiscalYearStart,
                                                MeasureCalculationGranularity.FISCAL_YEAR,
                                            ).fiscalMoment
                                        }
                                        max={
                                            calendarToFiscal(
                                                moment(`${MaxYear}-${fiscalYearMonthDigits}-01`),
                                                fiscalYearStart,
                                                MeasureCalculationGranularity.FISCAL_YEAR,
                                            ).fiscalMoment
                                        }
                                    />
                                    <SimpleCurrencyInput
                                        value={target}
                                        onChange={(target) => handleUpdateFiscalYearTarget(fiscalYear, target)}
                                        margin="none"
                                        disabled={disabled}
                                        fullWidth
                                    />
                                    <SmallIconButton onClick={() => handleRemoveFiscalYearTarget(fiscalYear)} disabled={disabled}>
                                        <DeleteIcon />
                                    </SmallIconButton>
                                </Fragment>
                            ))}
                    </TargetsGrid>
                ) : (
                    <NoTargetsContainer>
                        <Typography color="textSecondary">{translate(translationKeys.VDLANG_TARGETS_FORM_NO_TARGETS)}</Typography>
                    </NoTargetsContainer>
                )}
                <Button
                    onClick={() => handleAddFiscalYearTarget(hasMaxFiscalYear)}
                    disabled={(hasMaxFiscalYear && hasMinFiscalYear) || disabled}
                    startIcon={<AddIcon />}
                    sx={{ maxWidth: "fit-content" }}
                >
                    {translate(translationKeys.VDLANG_DASHBOARDS_LEGACY_PROJECT_PROGRESS_TARGETS_FORM_ADD_TARGET)}
                </Button>
            </Stack>
        </WidgetConfigTabContainer>
    );
};

export default LegacyProjectProgressConfigTabTargets;
