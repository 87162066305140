import { WidgetType } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../../components/Form";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useMeasureFieldDefinitionsQuery } from "../../../domain/filters";
import { useWaterfallFields } from "../../../domain/reporting";
import { useAlphanumericStringSort } from "../../../hooks/useAlphanumericStringSort";
import { translationKeys } from "../../../translations/main-translations";
import { IWidgetConfigFormProps } from "../WidgetConfigDialog";
import WidgetConfigTab from "../WidgetConfigTab";
import WidgetConfigTabs from "../WidgetConfigTabs";
import WaterfallConfigTabBlocks from "./tabs/WaterfallConfigTabBlocks";
import WaterfallConfigTabDescription from "./tabs/WaterfallConfigTabDescription";
import WaterfallConfigTabFilter from "./tabs/WaterfallConfigTabFilter";
import WaterfallConfigTabGeneral from "./tabs/WaterfallConfigTabGeneral";
import WaterfallConfigTabScope from "./tabs/WaterfallConfigTabScope";

const WaterfallConfig = ({
    config,
    onConfigChange,
    disabled,
    onNameChange,
    name,
    description,
    onDescriptionChange,
    onSubmit,
}: IWidgetConfigFormProps<WidgetType.Waterfall>) => {
    const { t } = useTranslation();
    const { compare } = useAlphanumericStringSort();
    const [openTab, setOpenTab] = useState(0);

    const fieldsQuery = useWaterfallFields();
    const fieldDefinitionsQuery = useMeasureFieldDefinitionsQuery();

    const fields = fieldsQuery.data ?? {};
    const fieldOptions = Object.values(fields)
        .map(({ name }) => ({
            label: t(name),
            value: name,
        }))
        .toSorted((a, b) => compare(a.label, b.label));

    if (!fieldDefinitionsQuery.isSuccess || !fieldsQuery.isSuccess) {
        return <LoadingAnimation />;
    }
    const selectedField = fieldOptions.find(({ value }) => value === config.attribute);
    const skipFields = selectedField ? [selectedField.value] : [];

    return (
        <Form onSubmit={onSubmit}>
            <WidgetConfigTabs value={openTab} onChange={(_, newValue) => setOpenTab(newValue)}>
                <WidgetConfigTab label={t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_GENERAL)} />
                <WidgetConfigTab label={t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_DESCRIPTION)} />
                <WidgetConfigTab label={t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_BLOCKS)} />
                <WidgetConfigTab label={t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_SCOPE)} />
                <WidgetConfigTab label={t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_FILTER)} />
            </WidgetConfigTabs>
            {openTab === 0 ? (
                <WaterfallConfigTabGeneral
                    config={config}
                    name={name}
                    onConfigChange={onConfigChange}
                    onNameChange={onNameChange}
                    disabled={disabled}
                />
            ) : null}
            {openTab === 1 ? (
                <WaterfallConfigTabDescription
                    description={description}
                    onDescriptionChange={onDescriptionChange}
                    disabled={disabled}
                    label={t(translationKeys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_DESCRIPTION)}
                />
            ) : null}
            {openTab === 2 ? (
                <WaterfallConfigTabBlocks
                    config={config}
                    onConfigChange={onConfigChange}
                    disabled={disabled}
                    fields={fields}
                    fieldOptions={fieldOptions}
                    selectedField={selectedField}
                />
            ) : null}
            {openTab === 3 ? (
                <WaterfallConfigTabScope config={config} disabled={disabled} onConfigChange={onConfigChange} skipFields={skipFields} />
            ) : null}
            {openTab === 4 ? (
                <WaterfallConfigTabFilter
                    config={config}
                    disabled={disabled}
                    fieldDefinitions={fieldDefinitionsQuery.data}
                    onConfigChange={onConfigChange}
                />
            ) : null}
        </Form>
    );
};

export default WaterfallConfig;
