import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";
import {
    Accordion,
    AccordionDetails,
    accordionDetailsClasses,
    AccordionSummary,
    accordionSummaryClasses,
    FormLabel,
    Grid,
    Link,
    Stack,
    styled,
    TextField,
    Typography,
} from "@mui/material";
import {
    FeatureFlags,
    FilterDefinition,
    ICompareExpression,
    MeasureCalculationGranularity,
    MeasureFieldNames,
    Operators,
    TimelineWidgetConfig,
    WidgetType,
} from "api-shared";
import { omit } from "lodash";
import moment from "moment";
import { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import FeatureFlag from "../../../components/FeatureFlag.tsx";
import Form from "../../../components/Form";
import CalculationTimeSelect from "../../../components/input/date/CalculationTimeSelect";
import { FiscalYearSelect } from "../../../components/input/select/FiscalYearSelect";
import PotentialTypeSelect from "../../../components/input/select/PotentialTypeSelect.tsx";
import { SimpleCurrencyInput } from "../../../components/input/SimpleCurrencyInput";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useClientFiscalYear, useCurrentClient } from "../../../domain/client";
import { useMeasureAttributes } from "../../../domain/endpoint";
import { useGateTaskConfigs } from "../../../domain/measure-config";
import { usePivotFields, useTimelineFieldDefinitions } from "../../../domain/reporting";
import { useNonDeletedUsers } from "../../../domain/users";
import { translationKeys } from "../../../translations/main-translations";
import FilterForm from "../../measures/preferences/filter-configuration/FilterForm";
import { useFieldOptions } from "../reporting/useFieldOptions.ts";
import { IWidgetConfigFormProps } from "../WidgetConfigDialog";
import WidgetConfigTab from "../WidgetConfigTab";
import WidgetConfigTabs from "../WidgetConfigTabs";
import WidgetDescriptionField from "../WidgetDescriptionField";
import FormulaInput from "./FormulaInput";

const DenseAccordion = styled(Accordion)(({ theme }) => ({
    border: "none",
    [`& .${accordionSummaryClasses.root}`]: {
        padding: 0,
        flexDirection: "row-reverse",
    },
    [`& .${accordionSummaryClasses.content}, & .${accordionSummaryClasses.content}.Mui-expanded`]: {
        margin: 0,
    },
    [`& .${accordionDetailsClasses.root}`]: {
        padding: theme.spacing(0.5, 0, 1),
    },
}));

const WidgetConfigTabContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(25),
}));

const TimelineWidgetConfigForm = ({
    config,
    onConfigChange,
    disabled,
    onNameChange,
    name,
    description,
    onDescriptionChange,
    onSubmit,
}: IWidgetConfigFormProps<WidgetType.Timeline>) => {
    const { t: translate } = useTranslation();
    const [openTab, setOpenTab] = useState(0);

    const fiscalYearStartMonth = useClientFiscalYear();
    const { fiscalYearRangePast, fiscalYearRangeFuture } = useCurrentClient();

    const measureAttributes = useMeasureAttributes();

    const fieldDefinitionsQuery = useTimelineFieldDefinitions();
    const users = useNonDeletedUsers();
    const [isValidFilter, setIsValidFilter] = useState(true);

    // Assign colors based on order in field options, so that colors are consistent with other widgets that are based on field options
    const fieldsQuery = usePivotFields();
    const fieldOptions = useFieldOptions({ definitions: fieldsQuery.data, fieldName: MeasureFieldNames.CurrentGateTaskConfigId }) ?? [];

    const gateTaskConfigs = useGateTaskConfigs();
    const extendedFieldOptions = fieldOptions.map((option) => ({
        ...option,
        calculationIdentifier: gateTaskConfigs.find((gtc) => gtc.id === +option.value)?.calculationIdentifier ?? null,
    }));

    const updateConfig = (newConfig: TimelineWidgetConfig, overrideIsValidFilter = isValidFilter) => {
        onConfigChange(newConfig, overrideIsValidFilter);
    };

    const newConditionBuilder = useCallback(() => {
        if (!fieldDefinitionsQuery.isSuccess) {
            // This cannot happen, as fields will be loaded when widget mounted
            // adding conditions can only happen after opening the config dialog
            throw new Error("Trying to add a filter condition but not field definitions are loaded");
        }
        return {
            field: Object.values(fieldDefinitionsQuery.data)[0].name,
            operator: Operators.In,
            values: [],
        } as ICompareExpression;
    }, [fieldDefinitionsQuery.isSuccess, fieldDefinitionsQuery.data]);

    if (!fieldDefinitionsQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    const now = moment();
    const currentTimeFrame = moment().year(config.timeFrame);

    const updateFormula = (id: string, formula: string) => {
        const otherFormulas = omit(config.curveFormulas, id);
        return onConfigChange({
            ...config,
            curveFormulas: {
                ...otherFormulas,
                ...(formula !== "" && { [id]: formula }),
            },
        });
    };

    return (
        <Form onSubmit={onSubmit}>
            <WidgetConfigTabs value={openTab} onChange={(_, newValue) => setOpenTab(newValue)}>
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TAB_GENERAL)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TAB_DESCRIPTION)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TAB_FILTER_CONFIGURATION)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TAB_FLIGHT_PATHS)} />
            </WidgetConfigTabs>

            {openTab === 0 ? (
                <WidgetConfigTabContainer>
                    <Grid container rowSpacing={2} columnSpacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                value={name}
                                onChange={(event) => onNameChange(event.target.value)}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_NAME)}
                                margin="none"
                                disabled={disabled}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <SimpleCurrencyInput
                                value={config.target}
                                onChange={(target) => updateConfig({ ...config, target })}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TARGET)}
                                margin="none"
                                disabled={disabled}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <SimpleCurrencyInput
                                value={config.axisMinValue}
                                onChange={(axisMinValue) => updateConfig({ ...config, axisMinValue })}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_AXIS_MIN_VALUE)}
                                margin="none"
                                disabled={disabled}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleCurrencyInput
                                value={config.axisMaxValue}
                                onChange={(axisMaxValue) => updateConfig({ ...config, axisMaxValue })}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_AXIS_MAX_VALUE)}
                                margin="none"
                                disabled={disabled}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FiscalYearSelect
                                value={config.fiscalYear}
                                onChange={(fiscalYear) => {
                                    if (fiscalYear !== null) {
                                        onConfigChange({ ...config, fiscalYear });
                                    }
                                }}
                                fiscalYearStartMonth={fiscalYearStartMonth}
                                yearsBefore={fiscalYearRangePast}
                                yearsAfter={fiscalYearRangeFuture}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_SCOPE)}
                                menuPortalTarget={document.body}
                                margin="none"
                                fullWidth
                                isDisabled={disabled}
                            />
                        </Grid>

                        {now.year() > 2024 ? (
                            <Grid item xs={6}>
                                <FormLabel sx={{ display: "block" }}>
                                    {translate(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TIME_FRAME)}
                                </FormLabel>
                                <CalculationTimeSelect
                                    // we currently plan to only provide snapshots for 2024 and beyond
                                    min={moment().year(2024)}
                                    max={now}
                                    value={currentTimeFrame}
                                    onChange={(timeFrame) => {
                                        if (timeFrame !== null) {
                                            onConfigChange({ ...config, timeFrame: timeFrame.year() });
                                        }
                                    }}
                                    translate={translate}
                                    fiscalYearStart={0}
                                    disabled={disabled}
                                    granularity={MeasureCalculationGranularity.FISCAL_YEAR}
                                    fullWidth
                                />
                            </Grid>
                        ) : null}
                        <FeatureFlag feature={FeatureFlags.FEATURE_RECURRING_EFFECTS}>
                            <Grid item xs={6}>
                                <PotentialTypeSelect
                                    value={config.potentialType}
                                    onChange={(potentialType) =>
                                        potentialType != null && onConfigChange({ ...config, potentialType }, isValidFilter)
                                    }
                                    isClearable={false}
                                    margin="none"
                                    menuPortalTarget={document.body}
                                />
                            </Grid>
                        </FeatureFlag>
                    </Grid>
                </WidgetConfigTabContainer>
            ) : null}

            {openTab === 1 ? (
                <WidgetConfigTabContainer>
                    <WidgetDescriptionField
                        description={description}
                        onDescriptionChange={onDescriptionChange}
                        label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_DESCRIPTION)}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}

            {openTab === 2 ? (
                <WidgetConfigTabContainer>
                    <FilterForm
                        filterDefinition={config.filter}
                        onChange={(newFilter: FilterDefinition, isValid: boolean) => {
                            onConfigChange({ ...config, filter: newFilter }, isValid);
                            setIsValidFilter(isValid);
                        }}
                        fieldDefinitions={fieldDefinitionsQuery.data}
                        measureAttributes={measureAttributes}
                        users={users}
                        translate={translate}
                        buildNewCondition={newConditionBuilder}
                        disabled={disabled}
                        ignoreConditionOperators={{ [MeasureFieldNames.MeasureConfigId]: [Operators.NotSet] }}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 3 ? (
                <WidgetConfigTabContainer>
                    <Stack spacing={2}>
                        <DenseAccordion variant="outlined" sx={{ border: "none" }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">
                                    {translate(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_FLIGHT_PATH_FORMULA_HEADING)}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <Trans i18nKey={translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_FLIGHT_PATH_FORMULA_HINT}>
                                        Enter a formula for calculating the flightpath here. Use the parameter <i>w</i>for the week number
                                        in your formula. Common
                                        <Link
                                            key="foo"
                                            href="https://mathjs.org/docs/reference/functions.html#arithmetic-functions"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            functions
                                        </Link>
                                        and
                                        <Link
                                            key="bar"
                                            href="https://mathjs.org/docs/reference/constants.html"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            constants
                                        </Link>
                                        are available. Make sure to enter the formula syntactically correct.
                                        <br />
                                        Example: f(w)=0.5*sin(24*w/pi)/2
                                    </Trans>
                                </Typography>
                            </AccordionDetails>
                        </DenseAccordion>
                        {extendedFieldOptions.map(({ value, label }) => (
                            <FormulaInput
                                key={value}
                                disabled={disabled}
                                fullWidth
                                label={translate(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_FLIGHT_PATH_FORMULA_LABEL, {
                                    level: label,
                                })}
                                value={config.curveFormulas[value] ?? ""}
                                onChange={(newFormula) => updateFormula(value, newFormula)}
                            />
                        ))}
                    </Stack>
                </WidgetConfigTabContainer>
            ) : null}
        </Form>
    );
};

export default TimelineWidgetConfigForm;
