import { styled } from "@mui/material";
import {
    AclNamespaces,
    AclPermissions,
    IdeaDto,
    IdeaListWidgetConfig,
    Sort,
    validateIdeaListWidgetConfig,
    WIDGET_PAGE_SIZE_OPTIONS,
    WidgetType,
} from "api-shared";
import { useCallback } from "react";
import { SortingRule } from "react-table";
import { useIdeaFieldDefinitionsQuery } from "../../domain/filters";
import { useIdeaAttributesQuery, useIdeaSearchQuery } from "../../domain/ideas";
import { useUserHasPermissionQuery } from "../../domain/permissions.ts";
import { ApiError } from "../../lib/api";
import { getTranslatedErrorMessage } from "../../lib/error";
import { IdeaTable } from "../ideas/IdeaTable";
import IdeaListWidgetConfigForm from "./IdeaListWidgetConfigForm";
import { IWidgetContentProps } from "./Widget";
import WidgetConfigDialog from "./WidgetConfigDialog";
import WidgetError from "./WidgetError";

const WidgetIdeaTable = styled(IdeaTable)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
}));

const IdeaListWidget = ({
    widget,
    isConfigDialogOpen,
    onConfigDialogClose,
    onConfigSave,
    translate,
    disabled = false,
    readOnlyLabel,
    isInView,
}: IWidgetContentProps) => {
    const ideasQuery = useIdeaSearchQuery((widget.config as IdeaListWidgetConfig).filter, { ignoreErrors: true, enabled: isInView });
    const attributesQuery = useIdeaAttributesQuery(isInView);
    const fieldDefinitionsQuery = useIdeaFieldDefinitionsQuery(isInView);
    const hasUpdatePermission = useUserHasPermissionQuery({
        namespace: AclNamespaces.Dashboard,
        permission: AclPermissions.Update,
        fact: { id: widget.dashboardId },
    });

    const handleSortingChange = useCallback(
        (newSortBy: SortingRule<IdeaDto>) => {
            if (hasUpdatePermission.data?.hasPermission === true) {
                onConfigSave({
                    config: {
                        ...(widget.config as IdeaListWidgetConfig),
                        orderBy: newSortBy.id,
                        sort: newSortBy.desc ? Sort.DESCENDING : Sort.ASCENDING,
                    },
                });
            }
        },
        [hasUpdatePermission.data?.hasPermission, onConfigSave, widget.config],
    );

    const handlePaginationChange = useCallback(
        (_pageSize: number, newPageSize: number) => {
            if (
                validateIdeaListWidgetConfig(widget.config) &&
                newPageSize !== widget.config.pageSize &&
                hasUpdatePermission.data?.hasPermission === true
            ) {
                onConfigSave({
                    config: {
                        ...widget.config,
                        pageSize: newPageSize,
                    },
                });
            }
        },
        [hasUpdatePermission.data?.hasPermission, onConfigSave, widget.config],
    );

    const handleColumnsResized = useCallback(
        (newWidths: Record<string, number>) => {
            if (hasUpdatePermission.data?.hasPermission === true) {
                onConfigSave({
                    config: {
                        ...widget.config,
                        columnWidths: Object.entries(newWidths).reduce(
                            (columnWidths, [column, width]) => ({ ...columnWidths, [column]: +width.toFixed(0) }),
                            {},
                        ),
                    },
                });
            }
        },
        [hasUpdatePermission.data?.hasPermission, onConfigSave, widget.config],
    );

    if (!validateIdeaListWidgetConfig(widget.config)) {
        return null;
    }

    return (
        <>
            <WidgetConfigDialog<WidgetType.IdeaList>
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={onConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateIdeaListWidgetConfig}
                FormComponent={IdeaListWidgetConfigForm}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
            />

            {ideasQuery.isError && ideasQuery.error instanceof ApiError ? (
                <WidgetError>{getTranslatedErrorMessage(ideasQuery.error, translate)}</WidgetError>
            ) : (
                attributesQuery.isSuccess && (
                    <WidgetIdeaTable
                        isFetching={ideasQuery.isFetching}
                        data={ideasQuery.data ?? []}
                        attributes={attributesQuery.data}
                        fieldDefinitions={fieldDefinitionsQuery.data ?? {}}
                        isDense
                        densePagination
                        columns={widget.config.columns}
                        defaultColumnWidths={widget.config.columnWidths}
                        onColumnsResized={handleColumnsResized}
                        pageSizeOptions={WIDGET_PAGE_SIZE_OPTIONS}
                        defaultPageSize={widget.config.pageSize}
                        onPaginationChanged={handlePaginationChange}
                        sort={widget.config.sort}
                        sortBy={widget.config.orderBy}
                        onSortByChanged={handleSortingChange}
                    />
                )
            )}
        </>
    );
};

export default IdeaListWidget;
