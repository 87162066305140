import { AggregationMethod, MeasureFieldNames, PivotMetric, WidgetDto, WidgetLocation } from "api-shared";
import type { TFunction } from "i18next";
import { groupBy, isNumber, sum, times } from "lodash";
import { useEffect, useState } from "react";
import { Layout } from "react-grid-layout";
import { translationKeys } from "../../translations/main-translations.ts";
import { FieldOption } from "./reporting/useFieldOptions.ts";

export const GRID_COLUMNS = 6;

export const ROW_HEIGHT_IN_SPACING_UNITS = 17;

export enum CalculatedStackKeys {
    DIFFERENCE_TO_TARGET = "differenceToTarget",
    OVERSHOOT = "overShoot",
    TARGET = "target",
    SUM = "sum",
    PREDICTED_SUM = "predictedSum",
    WATERFALL_BASE = "startValue",
}

/**
 * Get the height of the list of layouts. y coordinates are 0-indexed, so the height = maxY + 1
 *
 * @param {WidgetLocation[]} layouts
 */
export const getHeight = (layouts: WidgetLocation[], gap: number, rowHeight: number) => {
    // compute how many rows would fit the viewport (more or less, does not need to be pixel perfect)
    const baseNavbarHeight = 64;
    const minHeight = Math.ceil((window.innerHeight - baseNavbarHeight) / (rowHeight + gap));
    return layouts.reduce((height, { y, h }) => Math.max(height, y + h), minHeight);
};

/**
 * Traverse the DOM until finding widget's DOM element that contains the given element. The widget's DOM element is identified by the
 * data-grid attribute.
 *
 * @param {Element} element where to start the traversal
 * @returns {(Element | null)}
 */
export function findParentWidget(element: Element): Element | null {
    let iter: Element | null = element;
    while (iter != null && !iter.hasAttribute("data-grid")) {
        iter = iter.parentElement;
    }
    return iter ?? null;
}

interface IUsePlaceholdersProps {
    widgets: WidgetDto[];
    isDragging: boolean;
    gap: number;
    rowHeight: number;
}

export const usePlaceholders = ({ widgets, isDragging, gap, rowHeight }: IUsePlaceholdersProps) => {
    // number of rows will be updated on each resize/drag operation from the current layouts
    const [numberOfRows, setNumberOfRows] = useState(() =>
        getHeight(
            widgets.map(({ location }) => location),
            gap,
            rowHeight,
        ),
    );

    // Manually keep track of a widgets width because the grid layout component is a bit wonky when a scrollbar appears
    const [placeholderWidth, setPlaceholderWidth] = useState(0);

    // When a widget is dragged, show an additional row of placeholders on the bottom as drop target
    const placeholderRows = isDragging ? numberOfRows + 1 : numberOfRows;

    const placeholderCount = Math.ceil(placeholderRows) * GRID_COLUMNS;
    const placeholders = times(placeholderCount, (key) => ({ key, style: { width: widgets.length > 0 ? placeholderWidth : "100%" } }));

    const updatePlaceholderWidth = (width: number, layout: Layout) => {
        // if the widget spans multiple columns, compute the correct width of a single-column widget
        const numberOfGaps = layout.w - 1;
        const columnWidth = (width - numberOfGaps * gap) / layout.w;
        setPlaceholderWidth(columnWidth);
    };

    const updatePlaceholderRows = (layout: WidgetLocation[]) => setNumberOfRows(getHeight(layout, gap, rowHeight));

    // make sure the amount of placeholders are in sync when the widgets change
    // This may happen when widgets are added/removed
    useEffect(() => {
        setNumberOfRows(
            getHeight(
                widgets.map(({ location }) => location),
                gap,
                rowHeight,
            ),
        );
    }, [widgets, gap, rowHeight]);

    return {
        updatePlaceholderRows,
        placeholders,
        updatePlaceholderWidth,
    };
};

/**
 * Get widget anchor id
 *
 * @param {number} widgetId
 */
export const getWidgetId = (widgetId: number) => `widget-${widgetId}`;

/**
 * Scroll view to specific element
 * @param id
 */
export const handleScrollToElement = (id: string) => {
    const element = document.getElementById(id);
    element?.scrollIntoView(true);
};

type ComputedFieldOptions = {
    options: FieldOption[];
    deletedIds?: string[];
};

export const DELETED_USER_ID = -1;

const groupFieldOptionsByUserStatus = (fieldOptions: FieldOption[], deletedUserIds: number[]): ComputedFieldOptions => {
    if (deletedUserIds.length <= 1) {
        return { options: fieldOptions };
    }

    const groupedOptions = groupBy(fieldOptions, (option) => {
        return deletedUserIds?.includes(Number(option.value)) ? "deleted" : "active";
    });

    if (groupedOptions?.deleted === undefined || groupedOptions.deleted.length <= 1) {
        return { options: fieldOptions };
    }

    const activeEntries = groupedOptions?.active ?? [];
    const deletedEntry: FieldOption[] = [{ value: String(DELETED_USER_ID), label: groupedOptions.deleted[0].label }];

    return { deletedIds: groupedOptions.deleted.map(({ value }) => value), options: [...activeEntries, ...deletedEntry] };
};

export const calculateFieldOptionsWithDeletedIds = (
    pivotField: string | null,
    fieldOptions: FieldOption[],
    deletedUserIds: number[],
): ComputedFieldOptions => {
    let computedOptions = fieldOptions;
    let deletedDrilldownIds: string[] | undefined = undefined;

    if (pivotField === MeasureFieldNames.AssignedToId) {
        const { options, deletedIds } = groupFieldOptionsByUserStatus(fieldOptions, deletedUserIds);
        deletedDrilldownIds = deletedIds;
        computedOptions = options;
    }

    return { options: computedOptions, deletedIds: deletedDrilldownIds };
};

type PivotData = { value: number; fields: Record<string, unknown> }[];

export const combineDeletedUsersData = (dataList: PivotData, deletedUserIds: number[]) =>
    dataList.map((data) => {
        if (deletedUserIds.includes(Number(data.fields.assignedToId))) {
            const newData = { ...data, fields: { ...data.fields } };
            newData.fields.assignedToId = String(DELETED_USER_ID);
            return newData;
        }
        return data;
    });

export const getDifferenceToTarget = (target: number, item: Record<string, string | number>): number => {
    const itemSum = sum(Object.values(item).filter(isNumber));
    return Math.max(0, target - itemSum);
};

export const getOvershootToTarget = (target: number, item: Record<string, string | number>): number => {
    const itemSum = sum(Object.values(item).filter(isNumber));
    return Math.max(0, itemSum - target);
};

export function getAggregationOptions(translate: TFunction, hasRecurringFeature: boolean) {
    const allOptions = [
        {
            label: translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_SUM_OF_POTENTIAL),
            value: {
                aggregation: AggregationMethod.Sum,
                metric: PivotMetric.Effect,
            },
        },
        {
            label: translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_SUM_OF_CUMULATED_POTENTIAL),
            value: {
                aggregation: AggregationMethod.Sum,
                metric: PivotMetric.CumulatedEffect,
            },
        },
        {
            label: translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_SUM_OF_RECURRING_POTENTIAL),
            value: {
                aggregation: AggregationMethod.Sum,
                metric: PivotMetric.RecurringEffect,
            },
        },
        {
            label: translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_COUNT_OF_PROCESSES),
            value: {
                aggregation: AggregationMethod.Count,
                metric: PivotMetric.MeasureId,
            },
        },
    ];
    if (hasRecurringFeature) {
        return allOptions;
    }
    return allOptions.filter(
        (option) => option.value.metric !== PivotMetric.RecurringEffect && option.value.metric !== PivotMetric.CumulatedEffect,
    );
}
