import WarningIcon from "@mui/icons-material/WarningRounded";
import { Stack } from "@mui/material";
import { ProjectProgressBarListDto, ProjectProgressWidgetConfig, WidgetType } from "api-shared";
import { isEqual } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../../components/Form.tsx";
import { useMeasureFieldDefinitionsQuery } from "../../../domain/filters.ts";
import { translationKeys } from "../../../translations/main-translations.ts";
import { IWidgetConfigFormProps } from "../WidgetConfigDialog.tsx";
import WidgetConfigTab from "../WidgetConfigTab.tsx";
import WidgetConfigTabs from "../WidgetConfigTabs.tsx";
import ProjectProgressConfigTabDescription from "./tabs/ProjectProgressConfigTabDescription.tsx";
import ProjectProgressConfigTabFilterConfiguration from "./tabs/ProjectProgressConfigTabFilterConfiguration.tsx";
import ProjectProgressConfigTabGeneral from "./tabs/ProjectProgressConfigTabGeneral.tsx";
import ProjectProgressConfigTabReferenceValues from "./tabs/ProjectProgressConfigTabReferenceValues.tsx";
import ProjectProgressConfigTabScope from "./tabs/ProjectProgressConfigTabScope.tsx";
import ProjectProgressConfigTabTargets from "./tabs/ProjectProgressConfigTabTargets.tsx";

const ProjectProgressWidgetConfigForm = ({
    config,
    disabled,
    name,
    description,
    onConfigChange,
    onDescriptionChange,
    onNameChange,
    onSubmit,
    data,
}: IWidgetConfigFormProps<WidgetType.ProjectProgress, ProjectProgressBarListDto>) => {
    const [openTab, setOpenTab] = useState(0);
    const { t: translate } = useTranslation();

    const fieldDefinitionsQuery = useMeasureFieldDefinitionsQuery();

    const [isValidFilter, setIsValidFilter] = useState(true);

    const updateConfig = (newConfig: ProjectProgressWidgetConfig, overrideIsValidFilter = isValidFilter) => {
        onConfigChange(newConfig, overrideIsValidFilter);
    };

    const referenceValuesFromData = data?.reduce<Record<string, number>>((acc, item) => {
        acc[item.fiscalBucketIdentifier] = Object.values(item.data)
            .filter((value): value is number => typeof value === "number")
            .reduce((sum, num) => sum + num, 0);
        return acc;
    }, {});

    const onReferenceValuesDelete = () => {
        updateConfig({ ...config, referenceValues: null });
    };

    const onReferenceValuesSave = () => {
        updateConfig({
            ...config,
            referenceValues: {
                timestamp: new Date().toISOString(),
                data: referenceValuesFromData ?? {},
                config: {
                    filter: config.filter,
                    scope: config.scope,
                    potentialType: config.potentialType,
                },
            },
        });
    };
    const currentConfig = {
        filter: config.filter,
        scope: config.scope,
        potentialType: config.potentialType,
    };

    const currentConfigMatchesReferenceValuesConfig =
        config.referenceValues === null ? true : isEqual(currentConfig, config.referenceValues.config);

    return (
        <Form onSubmit={onSubmit}>
            <WidgetConfigTabs value={openTab} onChange={(_, newValue) => setOpenTab(newValue)}>
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_GENERAL)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_DESCRIPTION)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_SCOPE)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_FILTER_CONFIGURATION)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_TARGETS)} />
                <WidgetConfigTab
                    label={
                        <Stack direction="row" spacing={1} alignItems="center">
                            <span>{translate(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_REFERENCE_VALUES)}</span>
                            {!currentConfigMatchesReferenceValuesConfig ? <WarningIcon fontSize="inherit" /> : null}
                        </Stack>
                    }
                />
            </WidgetConfigTabs>
            {openTab === 0 ? (
                <ProjectProgressConfigTabGeneral
                    config={config}
                    name={name}
                    onNameChange={onNameChange}
                    onConfigChange={onConfigChange}
                    disabled={disabled}
                    isValidFilter={isValidFilter}
                />
            ) : null}
            {openTab === 1 ? (
                <ProjectProgressConfigTabDescription
                    description={description}
                    onDescriptionChange={onDescriptionChange}
                    disabled={disabled}
                    label={translate(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_DESCRIPTION)}
                />
            ) : null}
            {openTab === 2 ? (
                <ProjectProgressConfigTabScope
                    config={config}
                    onConfigChange={onConfigChange}
                    isValidFilter={isValidFilter}
                    disabled={disabled}
                />
            ) : null}
            {openTab === 3 && fieldDefinitionsQuery.isSuccess ? (
                <ProjectProgressConfigTabFilterConfiguration
                    config={config}
                    disabled={disabled}
                    updateConfig={updateConfig}
                    setIsValidFilter={setIsValidFilter}
                    fieldDefinitions={fieldDefinitionsQuery.data}
                />
            ) : null}
            {openTab === 4 ? (
                <ProjectProgressConfigTabTargets
                    targets={config.targets ?? {}}
                    onChange={(targets) => updateConfig({ ...config, targets })}
                    disabled={disabled}
                    translate={translate}
                />
            ) : null}
            {openTab === 5 ? (
                <ProjectProgressConfigTabReferenceValues
                    scope={config.referenceValues?.config.scope ?? config.scope}
                    referenceValues={config.referenceValues}
                    disabled={disabled}
                    onSave={onReferenceValuesSave}
                    onDelete={onReferenceValuesDelete}
                />
            ) : null}
        </Form>
    );
};

export default ProjectProgressWidgetConfigForm;
