import SwapHorizontalCircleOutlined from "@mui/icons-material/SwapHorizontalCircleOutlined";
import WarningIcon from "@mui/icons-material/WarningRounded";
import { FormControlLabel, Grid, IconButton, Stack, styled, Switch, TextField, Tooltip } from "@mui/material";
import { AggregationMethod, AttributeTable, ChartLayout, FeatureFlags, FilterDefinition, Sort, WidgetType } from "api-shared";
import { isEqual } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Alert from "../../../components/Alert.tsx";
import Form from "../../../components/Form";
import InfoIcon from "../../../components/icons/InfoIcon.tsx";
import Select from "../../../components/input/select/Select";
import { SimpleCurrencyInput } from "../../../components/input/SimpleCurrencyInput";
import FieldTreeInput from "../../../components/input/tree/FieldTreeInput";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useClientFiscalYear, useClientHasFeature, useCurrentClient } from "../../../domain/client";
import { useMeasureAttributes } from "../../../domain/endpoint";
import { useMeasureFieldDefinitionsQuery } from "../../../domain/filters";
import { useMeasureConfigs } from "../../../domain/measure-config";
import { usePivotFields } from "../../../domain/reporting";
import { useNonDeletedUsers } from "../../../domain/users";
import { findField } from "../../../lib/fields.ts";
import { translationKeys } from "../../../translations/main-translations";
import FilterForm from "../../measures/preferences/filter-configuration/FilterForm";
import ScopeSelect from "../../measures/preferences/ScopeSelect";
import TreeProvider from "../../TreeProvider.tsx";
import { getAggregationOptions } from "../utils.ts";
import { IWidgetConfigFormProps } from "../WidgetConfigDialog";
import WidgetConfigTab from "../WidgetConfigTab";
import WidgetConfigTabs from "../WidgetConfigTabs";
import WidgetDescriptionField from "../WidgetDescriptionField";
import CustomBarChartReferenceValuesForm from "./CustomBarChartReferenceValuesForm";
import { CustomBarChartConfigFormData } from "./CustomBarChartWidget";
import TargetsForm from "./targets-configuration/TargetsForm";

const TooltipInfoIcon = styled(InfoIcon)(({ theme }) => ({
    color: theme.palette.action.active,
}));

const WidgetConfigTabContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(25), // fixed height of the largest tab
}));

export enum CustomBarChartSorting {
    TitleAsc = "titleAscending",
    TitleDesc = "titleDescending",
    AggregationAsc = "aggregationAscending",
    AggregationDesc = "aggregationDescending",
    FieldOrderAsc = "fieldOrderAscending",
    FieldOrderDesc = "fieldOrderDescending",
}

const ORDERS = {
    [CustomBarChartSorting.TitleAsc]: translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TITLE_ASCENDING,
    [CustomBarChartSorting.TitleDesc]: translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TITLE_DESCENDING,
    [CustomBarChartSorting.AggregationAsc]: translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_ASCENDING,
    [CustomBarChartSorting.AggregationDesc]: translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_DESCENDING,
    [CustomBarChartSorting.FieldOrderAsc]: translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_FIELD_ORDER_ASCENDING,
    [CustomBarChartSorting.FieldOrderDesc]: translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_FIELD_ORDER_DESCENDING,
};

const BAR_DISPLAY_TYPES = {
    [ChartLayout.Horizontal]: translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_BAR_HORIZONTAL,
    [ChartLayout.Vertical]: translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_BAR_VERTICAL,
};

const CustomBarChartWidgetConfigForm = ({
    config,
    name,
    description,
    onNameChange,
    onDescriptionChange,
    onConfigChange,
    onSubmit,
    disabled,
    data,
}: IWidgetConfigFormProps<WidgetType.CustomBarChart, CustomBarChartConfigFormData>) => {
    const { t: translate } = useTranslation();
    const pivotFieldsQuery = usePivotFields();

    const [openTab, setOpenTab] = useState(0);

    const fieldDefinitionsQuery = useMeasureFieldDefinitionsQuery();
    const measureAttributes = useMeasureAttributes();
    const users = useNonDeletedUsers();
    const client = useCurrentClient();
    const fiscalYear = useClientFiscalYear();
    const measureConfigs = useMeasureConfigs();
    const [isValidFilter, setIsValidFilter] = useState(true);

    const hasRecurringFeature = useClientHasFeature(FeatureFlags.FEATURE_RECURRING_EFFECTS);

    if (pivotFieldsQuery.isLoading) {
        return <LoadingAnimation />;
    }

    const firstPivotField = findField(measureAttributes, fieldDefinitionsQuery.data ?? {}, config.firstPivotField);

    const currentConfig = {
        aggregation: config.aggregation,
        firstPivotField: config.firstPivotField,
        filter: config.filter,
        scope: config.scope,
        metric: config.metric,
    };
    const currentConfigMatchesReferenceValuesConfig =
        config.referenceValues === null ? true : isEqual(currentConfig, config.referenceValues.config);

    const fieldOptions = Object.values(pivotFieldsQuery.data ?? {}).map(({ name }) => ({
        label: translate(name),
        value: name,
    }));

    const selectedFirstPivotField = fieldOptions.find(({ value }) => value === config.firstPivotField);
    const selectedSecondPivotField = fieldOptions.find(({ value }) => value === config.secondPivotField);
    const hasSecondPivotField = selectedSecondPivotField !== undefined;

    const filteredOptions = fieldOptions.filter(({ value }) => value !== config.firstPivotField && value !== config.secondPivotField);

    const aggregationOptions = getAggregationOptions(translate, hasRecurringFeature);
    const selectedAggregationOption = aggregationOptions.find(
        ({ value }) => value.aggregation === config.aggregation && value.metric === config.metric,
    );

    const orderOptions = Object.entries(ORDERS).map(([key, value]) => ({
        label: translate(value),
        value: key,
    }));

    const selectedOption = orderOptions.find(({ value }) => value === config.orderBy);
    const selectedOrderOption = selectedOption ?? orderOptions[3];

    const barDisplayOption = Object.entries(BAR_DISPLAY_TYPES).map(([key, value]) => ({
        label: translate(value),
        value: key,
    }));
    const barDisplayValue = barDisplayOption.find(({ value }) => value === config.barDisplayLayout);
    const selectedBarDisplay = (value: string) => {
        return value === ChartLayout.Horizontal ? ChartLayout.Horizontal : ChartLayout.Vertical;
    };

    const selectedSort = (value: string) => {
        return value === CustomBarChartSorting.AggregationAsc ||
            value === CustomBarChartSorting.TitleAsc ||
            value === CustomBarChartSorting.FieldOrderAsc
            ? Sort.ASCENDING
            : Sort.DESCENDING;
    };

    const handlePivotFieldSwap = () => {
        if (config.secondPivotField !== null) {
            onConfigChange(
                { ...config, firstPivotField: config.secondPivotField, secondPivotField: config.firstPivotField },
                isValidFilter,
            );
        }
    };

    const referenceValuesFromData = data?.reduce<Record<string, number>>((acc, item) => {
        const key = (item.fields[config.firstPivotField] as string) ?? "null";
        acc[key] = Number(item.value) + Number(acc[key] || 0);
        return acc;
    }, {});

    const onReferenceValuesSave = () => {
        onConfigChange({
            ...config,
            referenceValues: {
                timestamp: new Date().toISOString(),
                data: referenceValuesFromData ?? {},
                config: {
                    aggregation: config.aggregation,
                    firstPivotField: config.firstPivotField,
                    filter: config.filter,
                    scope: config.scope,
                    metric: config.metric,
                },
            },
        });
    };

    const onReferenceValuesDelete = () => {
        onConfigChange({ ...config, referenceValues: null });
    };

    const firstStartNodeId = config.defaultStartTreeNodeIds?.[config.firstPivotField];
    return (
        <Form onSubmit={onSubmit}>
            <WidgetConfigTabs value={openTab} onChange={(event, newValue) => setOpenTab(newValue)}>
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_GENERAL)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_DESCRIPTION)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_SCOPE)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_FILTER)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_TARGETS)} />
                <WidgetConfigTab
                    label={
                        <Stack direction="row" spacing={1} alignItems="center">
                            <span>{translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_REFERENCE_VALUES)}</span>
                            {!currentConfigMatchesReferenceValuesConfig ? <WarningIcon fontSize="inherit" /> : null}
                        </Stack>
                    }
                />
            </WidgetConfigTabs>
            {openTab === 0 ? (
                <WidgetConfigTabContainer>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                value={name}
                                onChange={(event) => onNameChange(event.target.value)}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_NAME)}
                                margin="none"
                                disabled={disabled}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                value={barDisplayValue ?? barDisplayOption[0]}
                                options={barDisplayOption}
                                onChange={(option) =>
                                    option != null &&
                                    onConfigChange(
                                        {
                                            ...config,
                                            barDisplayLayout: selectedBarDisplay(option.value),
                                        },
                                        isValidFilter,
                                    )
                                }
                                label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_BARTYPE)}
                                menuPortalTarget={document.body}
                                margin="none"
                                fullWidth
                                isDisabled={disabled}
                                isMulti={false} // help the compiler picking the correct generics
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item xs flexGrow={1}>
                                    <Select
                                        value={selectedFirstPivotField}
                                        options={filteredOptions}
                                        onChange={(option) =>
                                            option != null && onConfigChange({ ...config, firstPivotField: option.value }, isValidFilter)
                                        }
                                        label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_FIRST_PIVOT_FIELD)}
                                        menuPortalTarget={document.body}
                                        margin="none"
                                        fullWidth
                                        isMulti={false} // help the compiler picking the correct generics
                                        isDisabled={disabled}
                                    />
                                </Grid>
                                <Grid item flexShrink={0}>
                                    <IconButton
                                        color="default"
                                        onClick={handlePivotFieldSwap}
                                        disabled={disabled || !hasSecondPivotField}
                                        sx={{ p: 1 }}
                                    >
                                        <SwapHorizontalCircleOutlined />
                                    </IconButton>
                                </Grid>
                                <Grid item xs flexGrow={1}>
                                    <Select
                                        value={selectedSecondPivotField}
                                        options={filteredOptions}
                                        onChange={(option) =>
                                            onConfigChange({ ...config, secondPivotField: option?.value ?? null }, isValidFilter)
                                        }
                                        label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_SECOND_PIVOT_FIELD)}
                                        menuPortalTarget={document.body}
                                        margin="none"
                                        fullWidth
                                        isMulti={false} // help the compiler picking the correct generics
                                        isDisabled={disabled}
                                        isClearable
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {firstPivotField?.tableName === AttributeTable.TreeNodes ? (
                            <Grid item xs={12}>
                                <TreeProvider
                                    componentProps={{
                                        label: (
                                            <Tooltip
                                                title={translate(
                                                    translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_DEFAULT_TREE_FIELD_LEVEL_HINT,
                                                )}
                                            >
                                                <span>
                                                    {translate(
                                                        translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_DEFAULT_TREE_FIELD_LEVEL,
                                                    )}{" "}
                                                    <TooltipInfoIcon />
                                                </span>
                                            </Tooltip>
                                        ),
                                        field: firstPivotField,
                                        updateValue: (value: number[] | number | null) => {
                                            onConfigChange(
                                                {
                                                    ...config,
                                                    defaultStartTreeNodeIds: {
                                                        ...config.defaultStartTreeNodeIds,
                                                        [config.firstPivotField]: Array.isArray(value) ? value[0] : value,
                                                    },
                                                },
                                                isValidFilter,
                                            );
                                        },
                                        value: firstStartNodeId ?? null,
                                        isMulti: false,
                                        isClearable: true,
                                        translate,
                                        disabled,
                                        menuPortalTarget: document.body,
                                        narrowSelection: false,
                                    }}
                                    field={firstPivotField}
                                    component={FieldTreeInput}
                                />
                            </Grid>
                        ) : null}
                        <Grid item xs={12}>
                            <Select
                                value={selectedOrderOption}
                                options={orderOptions}
                                onChange={(option) =>
                                    option != null &&
                                    onConfigChange(
                                        {
                                            ...config,
                                            orderBy: option.value,
                                            sort: selectedSort(option.value),
                                        },
                                        isValidFilter,
                                    )
                                }
                                label={translate(translationKeys.VDLANG_DESK_SORT_BY)}
                                menuPortalTarget={document.body}
                                margin="none"
                                fullWidth
                                isDisabled={disabled}
                                isMulti={false} // help the compiler picking the correct generics
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                value={selectedAggregationOption}
                                options={aggregationOptions}
                                onChange={(option) => option != null && onConfigChange({ ...config, ...option.value }, isValidFilter)}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION)}
                                menuPortalTarget={document.body}
                                margin="none"
                                fullWidth
                                isDisabled={disabled}
                                isMulti={false} // help the compiler picking the correct generics
                            />
                        </Grid>
                        {selectedAggregationOption?.value.aggregation === AggregationMethod.Count ? (
                            <Grid direction="row" flexWrap="nowrap" display="flex" alignItems="center" item xs={12} container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        type="number"
                                        value={config.axisMinCountValue}
                                        onChange={(event) =>
                                            onConfigChange({
                                                ...config,
                                                axisMinCountValue: event.target.value ? Number(event.target.value) : null,
                                            })
                                        }
                                        label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_AXIS_MIN_VALUE)}
                                        margin="none"
                                        disabled={disabled || config.showRelativeRepresentation}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        type="number"
                                        value={config.axisMaxCountValue}
                                        onChange={(event) =>
                                            onConfigChange({
                                                ...config,
                                                axisMaxCountValue: event.target.value ? Number(event.target.value) : null,
                                            })
                                        }
                                        label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_AXIS_MAX_VALUE)}
                                        margin="none"
                                        disabled={disabled || config.showRelativeRepresentation}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid direction="row" flexWrap="nowrap" display="flex" alignItems="center" item xs={12} container spacing={2}>
                                <Grid item xs={6}>
                                    <SimpleCurrencyInput
                                        value={config.axisMinPotentialValue}
                                        onChange={(axisMinPotentialValue) => onConfigChange({ ...config, axisMinPotentialValue })}
                                        label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_AXIS_MIN_VALUE)}
                                        margin="none"
                                        disabled={disabled || config.showRelativeRepresentation}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SimpleCurrencyInput
                                        value={config.axisMaxPotentialValue}
                                        onChange={(axisMaxPotentialValue) => onConfigChange({ ...config, axisMaxPotentialValue })}
                                        label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_AXIS_MAX_VALUE)}
                                        margin="none"
                                        disabled={disabled || config.showRelativeRepresentation}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Stack spacing={2}>
                                <FormControlLabel
                                    control={<Switch size="small" edge="start" />}
                                    label={translate(translationKeys.VDLANG_DASHBOARDS_LIVE_RUN_UP_SHOW_SUMS)}
                                    checked={config.showSums}
                                    onChange={(_e, checked) => onConfigChange({ ...config, showSums: checked }, isValidFilter)}
                                    disabled={disabled}
                                />
                                {config.showSums ? (
                                    <FormControlLabel
                                        control={<Switch size="small" edge="start" />}
                                        label={translate(translationKeys.VDLANG_DASHBOARDS_LIVE_RUN_UP_SHOW_REFERENCE_VALUES)}
                                        checked={config.showReferenceValues}
                                        onChange={(_e, checked) =>
                                            onConfigChange({ ...config, showReferenceValues: checked }, isValidFilter)
                                        }
                                        disabled={disabled}
                                    />
                                ) : null}
                                <FormControlLabel
                                    control={<Switch size="small" edge="start" />}
                                    label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_SHOW_RELATIVE_REPRESENTATION)}
                                    checked={config.showRelativeRepresentation}
                                    onChange={(_e, checked) =>
                                        onConfigChange({ ...config, showRelativeRepresentation: checked }, isValidFilter)
                                    }
                                    disabled={disabled || config.aggregation === AggregationMethod.Count}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 1 ? (
                <WidgetConfigTabContainer>
                    <WidgetDescriptionField
                        description={description}
                        onDescriptionChange={onDescriptionChange}
                        label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_DESCRIPTION)}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 2 ? (
                <WidgetConfigTabContainer>
                    <Grid container>
                        <Grid item xs={6}>
                            <ScopeSelect
                                value={config.scope}
                                onChange={(scope) => onConfigChange({ ...config, scope }, isValidFilter)}
                                disabled={disabled}
                                measureConfigs={measureConfigs}
                                measureConfigIds={measureConfigs.map((measureConfig) => measureConfig.id)}
                                financialMonth={fiscalYear}
                                yearsBefore={client.fiscalYearRangePast}
                                yearsAfter={client.fiscalYearRangeFuture}
                                menuPortalTarget={document.body}
                                translate={translate}
                            />
                        </Grid>
                    </Grid>
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 3 && fieldDefinitionsQuery.isSuccess ? (
                <WidgetConfigTabContainer>
                    <FilterForm
                        filterDefinition={config.filter}
                        onChange={(newFilter: FilterDefinition, isValid: boolean) => {
                            onConfigChange({ ...config, filter: newFilter }, isValid);
                            setIsValidFilter(isValid);
                        }}
                        fieldDefinitions={fieldDefinitionsQuery.data}
                        measureAttributes={measureAttributes}
                        users={users}
                        translate={translate}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 4 && fieldDefinitionsQuery.isSuccess ? (
                <WidgetConfigTabContainer>
                    <TargetsForm
                        fieldName={config.firstPivotField}
                        targets={config.targets != null ? { ...config.targets } : {}}
                        onChange={(targets) => onConfigChange({ ...config, targets }, isValidFilter)}
                        fieldDefinitions={fieldDefinitionsQuery.data}
                        targetFieldsOptions={fieldOptions}
                        translate={translate}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 5 ? (
                <WidgetConfigTabContainer>
                    {!currentConfigMatchesReferenceValuesConfig ? (
                        <Alert severity="warning" sx={{ mb: 2 }}>
                            {translate(translationKeys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIG_MISMATCH_HINT)}
                        </Alert>
                    ) : null}
                    <CustomBarChartReferenceValuesForm
                        referenceValues={config.referenceValues}
                        fieldDefinitions={fieldDefinitionsQuery.data ?? {}}
                        attributes={measureAttributes}
                        disabled={disabled}
                        onSave={onReferenceValuesSave}
                        onDelete={onReferenceValuesDelete}
                    />
                </WidgetConfigTabContainer>
            ) : null}
        </Form>
    );
};

export default CustomBarChartWidgetConfigForm;
