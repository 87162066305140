import { Grid, styled } from "@mui/material";
import { FilterDefinition, ScopeDto, WidgetType } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../../components/Form";
import { useCurrentClient } from "../../../domain/client";
import { useMeasureAttributes } from "../../../domain/endpoint";
import { useMeasureConfigs } from "../../../domain/measure-config";
import { useAllUsers } from "../../../domain/users";
import { translationKeys } from "../../../translations/main-translations";
import ScopeSelect from "../../measures/preferences/ScopeSelect";
import FilterForm from "../../measures/preferences/filter-configuration/FilterForm";
import { IWidgetConfigFormProps } from "../WidgetConfigDialog";
import WidgetConfigTab from "../WidgetConfigTab";
import WidgetConfigTabs from "../WidgetConfigTabs";
import WidgetDescriptionField from "../WidgetDescriptionField";
import RollingForecastGeneralConfiguration from "./RollingForecastGeneralConfiguration";
import TargetConfiguration from "./TargetConfiguration";

const WidgetConfigTabContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(25), // fixed height of the largest tab
}));

const RollingForecastConfigForm = ({
    config,
    name,
    description,
    onNameChange,
    onDescriptionChange,
    onConfigChange,
    onSubmit,
    disabled,
}: IWidgetConfigFormProps<WidgetType.RollingForecast>) => {
    const { t: translate } = useTranslation();
    const currentClient = useCurrentClient();
    const measureConfigs = useMeasureConfigs();
    const users = useAllUsers();
    const measureAttributes = useMeasureAttributes();

    const [openTab, setOpenTab] = useState(0);
    const [isValidFilter, setIsValidFilter] = useState(true);

    const handleFilterChange = (newFilter: FilterDefinition, isValid: boolean) => {
        setIsValidFilter(isValid);
        onConfigChange({ ...config, filter: newFilter }, isValid);
    };

    const handleScopeChange = ({ startDate, endDate, ...otherScope }: ScopeDto) => {
        if (startDate === null || endDate === null) {
            // ScopeSelect is configured to not return nullable Dates
            // Ignore any attempts to set the dates as such
            return;
        }

        onConfigChange({ ...config, scope: { startDate, endDate, ...otherScope } }, isValidFilter);
    };

    const handleCurrencyChange = (id: string, newValue: number) =>
        onConfigChange({ ...config, targetValues: { ...config.targetValues, [id]: newValue } }, isValidFilter);

    return (
        <Form onSubmit={onSubmit}>
            <WidgetConfigTabs value={openTab} onChange={(_, newValue) => setOpenTab(newValue)}>
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_TAB_GENERAL)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_TAB_DESCRIPTION)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_SCOPE)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_FILTER)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_TAB_TARGET)} />
            </WidgetConfigTabs>
            {openTab === 0 ? (
                <WidgetConfigTabContainer>
                    <RollingForecastGeneralConfiguration
                        name={name}
                        onNameChange={onNameChange}
                        config={config}
                        onConfigChange={(newConfig) => onConfigChange(newConfig, isValidFilter)}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 1 ? (
                <WidgetConfigTabContainer>
                    <WidgetDescriptionField
                        description={description}
                        onDescriptionChange={onDescriptionChange}
                        label={translate(translationKeys.VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_TAB_DESCRIPTION)}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 2 ? (
                <WidgetConfigTabContainer>
                    <Grid container>
                        <Grid item xs={6}>
                            <ScopeSelect
                                value={config.scope}
                                onChange={handleScopeChange}
                                measureConfigs={measureConfigs}
                                measureConfigIds={measureConfigs.map((mc) => mc.id)}
                                financialMonth={currentClient.fiscalYear}
                                yearsBefore={currentClient.fiscalYearRangePast}
                                yearsAfter={currentClient.fiscalYearRangeFuture}
                                translate={translate}
                                disabled={disabled}
                                menuPortalTarget={document.body}
                                showOnlyFiscalYears
                            />
                        </Grid>
                    </Grid>
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 3 ? (
                <WidgetConfigTabContainer>
                    <FilterForm
                        filterDefinition={config.filter}
                        onChange={handleFilterChange}
                        measureAttributes={measureAttributes}
                        users={users}
                        translate={translate}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 4 ? (
                <WidgetConfigTabContainer>
                    <TargetConfiguration
                        startDate={config.scope.startDate}
                        endDate={config.scope.endDate}
                        onTargetChange={handleCurrencyChange}
                        targetValues={config.targetValues}
                        translate={translate}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
        </Form>
    );
};

export default RollingForecastConfigForm;
