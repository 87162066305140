import CloseIcon from "@mui/icons-material/CloseRounded";
import { Dialog, Paper, styled } from "@mui/material";
import { AclNamespaces, AclPermissions, validateActivityListWidgetConfig, WidgetType, zActivityListWidgetConfig } from "api-shared";
import { useState } from "react";
import SubtaskDetail from "../../components/tasks/SubtaskDetail";
import SubtaskList from "../../components/tasks/SubtaskList";
import { useEditableSubtaskTitle } from "../../components/tasks/hooks";
import { useUserHasPermissionQuery } from "../../domain/permissions";
import { SubTaskUpdateChanges, useDiscardSubtask, useSubTasks, useUpdateSubtask } from "../../domain/subtasks";
import { useAllUsers, useCurrentUserId } from "../../domain/users";
import { filterAssignedCreatedIds } from "../../lib/subtask";
import ActivityListWidgetConfigForm from "./ActivityListWidgetConfigForm";
import { IWidgetContentProps } from "./Widget";
import WidgetConfigDialog from "./WidgetConfigDialog";

const DialogPaper = styled(Paper)({
    // Fix scrolling in SubtaskDetailContent
    // For the overflow auto inside of the detail content to work, it needs to have a height defined. The height is relative (100%) so the
    // browser needs to traverse the ancestors until it finds a non-relative height so the detail's scroll containers height can be
    // computed. All of the detail content's ancesters have a relative height set, so to keep the browser walking further up (until the
    // body element), the dialog's paper also needs a relative height of 100%
    height: "100%",
});

const ActivityListWidget = ({
    widget,
    isConfigDialogOpen,
    onConfigDialogClose,
    onConfigSave,
    translate,
    disabled = false,
    readOnlyLabel,
    isInView,
}: IWidgetContentProps) => {
    const { filter, sort, orderBy, status, assignedToIds, createdByIds } = zActivityListWidgetConfig.parse(widget.config);
    const hasPersonFilterPermissionQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.User,
        permission: AclPermissions.Filter,
        fact: {},
    });
    const currentUserId = useCurrentUserId();

    const canFilterByPerson = hasPersonFilterPermissionQuery.isSuccess && hasPersonFilterPermissionQuery.data.hasPermission;

    const [filteredAssignedToIds, filteredCreatedByIds] = canFilterByPerson
        ? [assignedToIds, createdByIds]
        : filterAssignedCreatedIds(currentUserId, assignedToIds, createdByIds);

    const filterOptions = {
        sort,
        orderBy,
        status,
        grouping: false,
        assignedToIds: filteredAssignedToIds,
        createdByIds: filteredCreatedByIds,
        filterDefinition: JSON.stringify(filter),
    };

    const activitiesQuery = useSubTasks({
        filter: filterOptions,
        enabled: isInView,
    });

    const updateSubTask = useUpdateSubtask();
    const discardSubTask = useDiscardSubtask();

    const users = useAllUsers();

    const [currentTaskId, setCurrentTaskId] = useState<number | null>(null);
    const updateCurrentSubtask = (changes: SubTaskUpdateChanges) => {
        currentTaskId != null && updateSubTask.mutate({ id: currentTaskId, ...changes });
    };

    const { modifiedTitle, setModifiedTitle, resetModifiedTitle, updateSubtaskTitle } = useEditableSubtaskTitle({
        updateSubtask: updateCurrentSubtask,
    });

    const closeDialog = () => {
        setCurrentTaskId(null);
        resetModifiedTitle();
    };

    return (
        <>
            <WidgetConfigDialog<WidgetType.ActivityList>
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={onConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateActivityListWidgetConfig}
                FormComponent={ActivityListWidgetConfigForm}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
            />
            <SubtaskList
                tasks={activitiesQuery.data?.tasks}
                translate={translate}
                users={users}
                onOpen={(item) => setCurrentTaskId(item.id)}
                isCreatable={false}
                currentTaskId={currentTaskId}
                title={modifiedTitle}
                showLevelChip={true}
            />
            <Dialog PaperComponent={DialogPaper} open={currentTaskId != null} onClose={closeDialog}>
                <SubtaskDetail
                    taskId={currentTaskId}
                    translate={translate}
                    onClose={closeDialog}
                    disabled={false}
                    updateModifiedTitle={setModifiedTitle}
                    onAbortTitle={resetModifiedTitle}
                    onTitleSave={updateSubtaskTitle}
                    updateTask={updateCurrentSubtask}
                    users={users}
                    onRemove={discardSubTask.mutate}
                    modifiedTitle={modifiedTitle}
                    CloseIcon={CloseIcon}
                />
            </Dialog>
        </>
    );
};

export default ActivityListWidget;
