import { CompletedEffectsWidgetConfig, validateCompletedEffectsWidgetConfig, WidgetType, zCompletedEffectsWidgetConfig } from "api-shared";
import VerticalBarChartSkeleton from "../../../components/loading/VerticalBarChartSkeleton";
import { useCompletedEffects, useCompletedEffectsFieldDefinitions } from "../../../domain/reporting";
import EmptyVerticalBarChartIllustration from "../../../static/images/widgets/empty-widget-bar.svg";
import { translationKeys } from "../../../translations/main-translations";
import { IWidgetContentProps } from "../Widget";
import WidgetConfigDialog from "../WidgetConfigDialog";
import WidgetNoData from "../WidgetNoData";
import ChartWidgetRoot from "./ChartWidgetRoot";
import CompletedEffectsWidgetConfigForm from "./CompletedEffectsWidgetConfigForm";
import WidgetBarChart from "./WidgetBarChart";
import { useFieldOptions } from "./useFieldOptions";

const CompletedEffectsWidget = ({
    widget,
    isConfigDialogOpen,
    onConfigDialogClose,
    onConfigSave,
    translate,
    disabled = false,
    readOnlyLabel,
    isInView,
}: IWidgetContentProps) => {
    // Make sure that the widget config is in fact of proper format
    const validatedConfig = zCompletedEffectsWidgetConfig.parse(widget.config);

    const completedEffectsQuery = useCompletedEffects(validatedConfig, isInView);
    const fieldDefinitionsQuery = useCompletedEffectsFieldDefinitions(isInView);

    const defaultGroups = [
        {
            value: "effect",
            label: translate(translationKeys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_LEGEND_EFFECTS),
        },
    ];

    const groups =
        useFieldOptions({
            definitions: fieldDefinitionsQuery.data ?? undefined,
            fieldName: (widget.config as CompletedEffectsWidgetConfig).group,
        }) ?? [];
    const groupsWithDefaults = [...groups, ...defaultGroups];

    const isEmpty =
        completedEffectsQuery.isSuccess &&
        completedEffectsQuery.data.every((snapshot) => Object.keys(snapshot).length === 1) &&
        validatedConfig.target === null;

    return (
        <ChartWidgetRoot>
            <WidgetConfigDialog<WidgetType.CompletedEffects>
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={onConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateCompletedEffectsWidgetConfig}
                FormComponent={CompletedEffectsWidgetConfigForm}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
            />

            {isEmpty ? <WidgetNoData src={EmptyVerticalBarChartIllustration} /> : null}
            {!completedEffectsQuery.isSuccess ? <VerticalBarChartSkeleton /> : null}
            {completedEffectsQuery.isSuccess && !isEmpty ? (
                <WidgetBarChart
                    data={completedEffectsQuery.data}
                    xAxis="snapshot"
                    target={validatedConfig.target}
                    showSums={validatedConfig.showSums}
                    groups={groupsWithDefaults}
                    axisMinValue={validatedConfig.axisMinValue}
                    axisMaxValue={validatedConfig.axisMaxValue}
                />
            ) : null}
        </ChartWidgetRoot>
    );
};

export default CompletedEffectsWidget;
