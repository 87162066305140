import { Grid, styled, TextField } from "@mui/material";
import { FilterDefinition, WidgetType } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Alert from "../../components/Alert";
import Form from "../../components/Form";
import { useMeasureAttributes } from "../../domain/endpoint";
import { useNonDeletedUsers } from "../../domain/users";
import { translationKeys } from "../../translations/main-translations";
import FilterForm from "../measures/preferences/filter-configuration/FilterForm";
import ActivitiesOrderSelect from "../tasks/preferences/ActivitiesOrderSelect";
import ActivitiesStatusSelect from "../tasks/preferences/ActivitiesStatusSelect";
import ActivityListWidgetUserSelect from "./ActivityListWidgetUserSelect";
import { IWidgetConfigFormProps } from "./WidgetConfigDialog";
import WidgetConfigTab from "./WidgetConfigTab";
import WidgetConfigTabs from "./WidgetConfigTabs";
import WidgetDescriptionField from "./WidgetDescriptionField";

const WidgetConfigTabContainer = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(2, 3),
    minHeight: theme.spacing(25), // fixed height of the largest tab
}));

const ActivityListWidgetConfigForm = ({
    config,
    name,
    description,
    onNameChange,
    onDescriptionChange,
    onConfigChange,
    onSubmit,
    disabled,
}: IWidgetConfigFormProps<WidgetType.ActivityList>) => {
    const { t: translate } = useTranslation();
    const [openTab, setOpenTab] = useState(0);
    const [isValidFilter, setIsValidFilter] = useState(true);
    const measureAttributes = useMeasureAttributes();
    const users = useNonDeletedUsers();

    const handleFilterChange = (newFilter: FilterDefinition, isValid: boolean) => {
        setIsValidFilter(isValid);
        onConfigChange({ ...config, filter: newFilter }, isValid);
    };

    return (
        <Form onSubmit={onSubmit}>
            <WidgetConfigTabs value={openTab} onChange={(event, newValue) => setOpenTab(newValue)}>
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_GENERAL)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_ACTIVITY_LIST_WIDGET_CONFIG_TAB_DESCRIPTION)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_FILTER)} />
            </WidgetConfigTabs>
            {openTab === 0 ? (
                <WidgetConfigTabContainer container spacing={2}>
                    <Grid item xs={12} py={1}>
                        <TextField disabled={disabled} fullWidth value={name} onChange={(e) => onNameChange(e.target.value)} label="Name" />
                    </Grid>
                    <ActivityListWidgetUserSelect
                        translate={translate}
                        disabled={disabled}
                        config={config}
                        onConfigChange={onConfigChange}
                    />
                    <Grid item xs={6}>
                        <ActivitiesStatusSelect
                            margin="none"
                            menuPortalTarget={document.body}
                            value={config.status}
                            onChange={(status) => status != null && onConfigChange({ ...config, status }, isValidFilter)}
                            isDisabled={disabled}
                            translate={translate}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ActivitiesOrderSelect
                            margin="none"
                            menuPortalTarget={document.body}
                            order={config.sort}
                            value={config.orderBy}
                            onChange={(orderBy, sort) =>
                                orderBy != null &&
                                sort != null &&
                                onConfigChange({ ...config, orderBy: orderBy, sort: sort }, isValidFilter)
                            }
                            isDisabled={disabled}
                            translate={translate}
                        />
                    </Grid>
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 1 ? (
                <WidgetConfigTabContainer>
                    <WidgetDescriptionField
                        description={description}
                        onDescriptionChange={onDescriptionChange}
                        label={translate(translationKeys.VDLANG_DASHBOARDS_ACTIVITY_LIST_WIDGET_CONFIG_TAB_DESCRIPTION)}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 2 ? (
                <WidgetConfigTabContainer>
                    <Alert severity="info" dense sx={{ mb: 2 }}>
                        {translate(translationKeys.VDLANG_DASHBOARDS_ACTIVITY_LIST_WIDGET_FILTER_HINT)}
                    </Alert>
                    <FilterForm
                        filterDefinition={config.filter}
                        onChange={handleFilterChange}
                        measureAttributes={measureAttributes}
                        users={users}
                        translate={translate}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
        </Form>
    );
};

export default ActivityListWidgetConfigForm;
