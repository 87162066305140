import { Card, Divider, styled, Typography } from "@mui/material";
import { nonNullable, TooltipLayout } from "api-shared";
import { uniqBy } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import type { TooltipProps } from "recharts";
import type { Formatter, NameType, Payload, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { useLanguage } from "../../../hooks/useLanguage";
import { withPercentage } from "../../../lib/formatters";
import { translationKeys } from "../../../translations/main-translations";
import { CalculatedStackKeys } from "../utils";

const TooltipHeader = styled("div")(({ theme }) => ({
    padding: theme.spacing(1, 1.5),
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const TooltipSubHeader = styled("div")(({ theme }) => ({
    paddingLeft: theme.spacing(1.5),
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(-0.5),
    color: theme.palette.grey[700],
    ...theme.typography.overline,
}));

const TooltipContentGrid = styled("div")(({ theme }) => ({
    padding: theme.spacing(1, 1.5),
    display: "grid",
    columnGap: theme.spacing(1),
    rowGap: theme.spacing(0.5),
    gridTemplateColumns: "auto 1fr auto",
    alignItems: "center",
}));

const HighlightTypography = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightMedium,
}));

const SubHeaderTypography = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.spacing(1.5),
    letterSpacing: theme.spacing(0.125),
}));

type ColorSymbolProps = Pick<React.SVGProps<SVGSVGElement>, "color" | "stroke" | "strokeWidth" | "strokeDasharray">;

type LegendType = "circle" | "line" | "linedashed";

const ColorCircle = ({ color, stroke, strokeWidth, strokeDasharray }: ColorSymbolProps) => (
    <svg width="12" height="12" viewBox="0 0 12 12">
        <circle fill={color} stroke={stroke} strokeWidth={strokeWidth} strokeDasharray={strokeDasharray} cx="6" cy="6" r="6" />
    </svg>
);

const ColorLine = ({ color, stroke, strokeWidth, strokeDasharray }: ColorSymbolProps) => (
    <svg width="12" height="1" viewBox="0 0 12 1" fill="none">
        <line
            x1="0.5"
            y1="0.5"
            x2="11.5"
            y2="0.499999"
            stroke={stroke ?? color}
            strokeWidth={strokeWidth ?? 2}
            strokeLinecap="round"
            strokeDasharray={strokeDasharray}
        />
    </svg>
);

const ColorLineDashed = ({ color, stroke, strokeWidth, strokeDasharray }: ColorSymbolProps) => {
    return (
        <svg width="12" height="2" viewBox="0 0 12 1" fill="none">
            <line
                x1="0.5"
                y1="0.5"
                x2="11.5"
                y2="0.499999"
                stroke={stroke ?? color}
                strokeWidth={strokeWidth ?? 2}
                strokeLinecap="round"
                strokeDasharray={strokeDasharray ?? "4 2"}
            />
        </svg>
    );
};

const LegendRepresentations = {
    circle: ColorCircle,
    line: ColorLine,
    linedashed: ColorLineDashed,
} as const;

export type ChartTooltipContentProps = Pick<
    TooltipProps<ValueType, NameType>,
    "payload" | "active" | "label" | "formatter" | "labelFormatter"
> & {
    legendTypes?: Record<string, LegendType>;
    hideSummary?: boolean;
    showDifferenceToTarget?: boolean;
    target?: number;
    showPercentageShares?: boolean;
    deduplicateBy?: string;
    tooltipLayout?: TooltipLayout;
    isFlightPath?: boolean;
    showName?: boolean;
};

const PercentageShare = ({ value, total }: { value: number | undefined; total: number }) => {
    const language = useLanguage();

    if (total === 0) {
        return null;
    }

    return `(${withPercentage(((value ?? 0) / total) * 100, language)})`;
};

const DifferenceToTarget = ({
    differenceToTargetPayload,
    calculatedDifferenceToTarget,
    percentageTarget,
    showPercentageShare,
    legendTypes,
    formatter,
}: {
    differenceToTargetPayload?: Payload<ValueType, NameType>;
    formatter?: Formatter<ValueType, NameType>;
    legendTypes?: Record<string, LegendType>;
    percentageTarget?: number;
    showPercentageShare?: boolean;
    calculatedDifferenceToTarget?: number;
}) => {
    if (differenceToTargetPayload === undefined) {
        return null;
    }

    const { value, color, name, stroke, strokeDasharray, strokeWidth } = differenceToTargetPayload;
    if (name == null) {
        return null;
    }

    const legendType = legendTypes?.[name] ?? "circle";
    const LegendComponent = LegendRepresentations[legendType];
    // Render calculated difference instead if passed; this allows to separate tooltip value from its visualization in the chart
    const differenceToTargetValue = calculatedDifferenceToTarget ?? value;

    return (
        <>
            {color != null && LegendComponent != null ? (
                <LegendComponent color={color} stroke={stroke} strokeWidth={strokeWidth} strokeDasharray={strokeDasharray} />
            ) : (
                <div />
            )}
            <Typography>{name}</Typography>
            {differenceToTargetValue != null ? (
                <Typography align="right">
                    {formatter ? formatter(differenceToTargetValue, "differenceToTarget", {}, -1, []) : differenceToTargetValue}{" "}
                    {showPercentageShare && percentageTarget !== undefined ? (
                        <PercentageShare total={percentageTarget} value={Number(differenceToTargetValue.valueOf())} />
                    ) : null}
                </Typography>
            ) : null}
        </>
    );
};

const Target = ({
    targetPayload,
    percentageShare = "",
    legendTypes,
    formatter,
}: {
    targetPayload?: Payload<ValueType, NameType>;
    formatter?: Formatter<ValueType, NameType>;
    legendTypes?: Record<string, LegendType>;
    percentageShare?: string;
}) => {
    if (targetPayload === undefined) {
        return null;
    }

    const { value, color, name, stroke, strokeDasharray, strokeWidth } = targetPayload;

    if (name == null) {
        return null;
    }

    const legendType = legendTypes?.[name] ?? "circle";
    const LegendComponent = LegendRepresentations[legendType];

    return (
        <>
            {color != null && LegendComponent != null ? (
                <LegendComponent color={color} stroke={stroke} strokeWidth={strokeWidth} strokeDasharray={strokeDasharray} />
            ) : (
                <div />
            )}
            <Typography>{name}</Typography>
            {value != null ? (
                <Typography align="right">
                    {formatter ? formatter(value, "target", {}, -1, []) : value} {percentageShare}
                </Typography>
            ) : null}
        </>
    );
};

function getPayloadByDataKey(
    payload: Payload<ValueType, NameType>[],
    key: string | number | undefined,
): Payload<ValueType, NameType> | undefined {
    return payload.find(({ dataKey }) => dataKey === key);
}

function filterPayloadByDataKeys(
    payload: Payload<ValueType, NameType>[],
    keys: (string | number | undefined)[],
): Payload<ValueType, NameType>[] {
    return payload.filter(({ dataKey }) => !keys.includes(dataKey));
}

const PayloadItem = ({
    item,
    index,
    percentageTarget,
    showPercentageShares,
    formatter,
    legendTypes,
    showName = true,
}: {
    item: Payload<ValueType, NameType>;
    index: number;
    formatter?: Formatter<ValueType, NameType>;
    legendTypes?: Record<string, "circle" | "line" | "linedashed">;
    showPercentageShares?: boolean;
    percentageTarget: number;
    showName?: boolean;
}) => {
    const { value, color, name, payload, stroke, strokeDasharray, strokeWidth } = item;

    if (name == null) {
        // legend entry without a name is not useful
        return null;
    }

    const legendType = legendTypes?.[name] ?? "circle";
    const LegendComponent = LegendRepresentations[legendType];

    return (
        <>
            {color != null && LegendComponent != null ? (
                <LegendComponent color={color} stroke={stroke} strokeWidth={strokeWidth} strokeDasharray={strokeDasharray} />
            ) : (
                <div />
            )}
            {showName ? <Typography key={`n${index}`}>{name}</Typography> : null}
            {value != null && payload != null ? (
                <Typography key={`v${index}`} align="right">
                    {formatter ? formatter(value, name, item, index, payload) : value}{" "}
                    {showPercentageShares ? <PercentageShare total={percentageTarget} value={Number(value.valueOf())} /> : null}
                </Typography>
            ) : null}
        </>
    );
};

const ChartTooltipContent = React.forwardRef<HTMLDivElement, ChartTooltipContentProps>(
    (
        {
            payload,
            active,
            label,
            formatter,
            labelFormatter,
            legendTypes,
            hideSummary,
            deduplicateBy,
            tooltipLayout,
            showDifferenceToTarget,
            target,
            showPercentageShares,
            isFlightPath,
            showName,
        },
        ref,
    ) => {
        const { t } = useTranslation();

        if (!active || payload == null || payload.length === 0) {
            // no tooltip needed in case of no payload
            return null;
        }

        // Hide summary if only one item is visible or when explicitly disabled
        const showSummary = !hideSummary && payload.length > 1;

        const differenceToTargetPayload = getPayloadByDataKey(payload, CalculatedStackKeys.DIFFERENCE_TO_TARGET);
        const overshootPayload = getPayloadByDataKey(payload, CalculatedStackKeys.OVERSHOOT);
        const calculatedSum =
            getPayloadByDataKey(payload, CalculatedStackKeys.SUM) ?? getPayloadByDataKey(payload, CalculatedStackKeys.PREDICTED_SUM);
        const targetPayload = getPayloadByDataKey(payload, CalculatedStackKeys.TARGET);
        const payloadWithoutCalculatedData = filterPayloadByDataKeys(payload, [
            CalculatedStackKeys.SUM,
            CalculatedStackKeys.PREDICTED_SUM,
            CalculatedStackKeys.DIFFERENCE_TO_TARGET,
            CalculatedStackKeys.TARGET,
            CalculatedStackKeys.OVERSHOOT,
            CalculatedStackKeys.WATERFALL_BASE,
        ]);

        const payloadWithoutSumGapAndFlightPath = payload.filter(
            ({ dataKey }) =>
                !dataKey?.toString().includes("curves") &&
                dataKey?.toString() !== CalculatedStackKeys.OVERSHOOT &&
                dataKey?.toString() !== CalculatedStackKeys.TARGET &&
                dataKey?.toString() !== CalculatedStackKeys.DIFFERENCE_TO_TARGET,
        );

        const total =
            calculatedSum?.value ??
            payloadWithoutSumGapAndFlightPath
                .map(({ value }) => value)
                .filter(nonNullable)
                .map(Number)
                .reduce((sum, value) => sum + value, 0);

        const visiblePayload = deduplicateBy != null ? uniqBy(payloadWithoutCalculatedData, deduplicateBy) : payloadWithoutCalculatedData;
        const sortedPayload = tooltipLayout === TooltipLayout.Reversed ? visiblePayload.toReversed() : visiblePayload;
        const sortedWithoutFlightPath = sortedPayload.filter((payload) => !payload.dataKey?.toString().includes(`curves`));

        const sortedFlightPath = sortedPayload.filter((payload) => payload.dataKey?.toString().includes(`curves`));

        // Use individual item's target if it is defined, else use the common target if that is defined
        const targetValue = targetPayload?.value != null ? Number(targetPayload.value.valueOf()) : target;
        const sum = Number(total.valueOf());

        // If target is defined, calculate the difference to target for use in the tooltip
        const calculatedDifferenceToTarget = targetValue !== undefined ? targetValue - sum : undefined;

        const percentageTarget = targetValue ?? sum;

        // If a explicit target payload (stack) is provided then show alternate difference to target where the target is shown in the chart and not the diff to target
        const showTarget = targetPayload !== undefined && targetValue !== undefined;
        const showDiff = showDifferenceToTarget === true && differenceToTargetPayload !== undefined && overshootPayload === undefined;
        const showDivider = showTarget || showDiff || showSummary;

        return (
            <Card ref={ref}>
                <TooltipHeader>
                    <HighlightTypography>{labelFormatter ? labelFormatter(label, payload) : label}</HighlightTypography>
                </TooltipHeader>
                {isFlightPath && (
                    <TooltipSubHeader>
                        <SubHeaderTypography>{t(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_TOOLTIP_GATETASK)}</SubHeaderTypography>
                    </TooltipSubHeader>
                )}
                <TooltipContentGrid>
                    {sortedWithoutFlightPath.map((item, index: number) => {
                        return (
                            <PayloadItem
                                key={`p_${item.name}_${index}`}
                                item={item}
                                index={index}
                                legendTypes={legendTypes}
                                formatter={formatter}
                                showPercentageShares={showPercentageShares}
                                percentageTarget={percentageTarget}
                                showName={showName}
                            />
                        );
                    })}

                    {showDivider && !isFlightPath ? <Divider sx={{ my: 0.5, gridColumn: "span 3" }} /> : null}

                    {showSummary && (
                        <>
                            {
                                // Show color of sum line in legend if there is a precalculated sum
                                calculatedSum?.color != null ? <ColorCircle color={calculatedSum.color} /> : <div />
                            }
                            <HighlightTypography ml={-2.5}>
                                {isFlightPath ? t(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_TOOLTIP_TOTAL) : t("total")}
                            </HighlightTypography>
                            <HighlightTypography align="right">
                                {formatter ? formatter(total, "total", {}, -1, []) : total}{" "}
                                {showPercentageShares ? <PercentageShare total={percentageTarget} value={Number(total.valueOf())} /> : null}
                            </HighlightTypography>
                        </>
                    )}

                    {showTarget ? (
                        <>
                            <Target targetPayload={targetPayload} legendTypes={legendTypes} formatter={formatter} />

                            {showDifferenceToTarget ? (
                                <>
                                    <div />
                                    <Typography>{t(translationKeys.VDLANG_DASHBOARDS_DIFFERENCE_TO_TARGET_LABEL)}</Typography>
                                    <Typography align="right">
                                        {formatter ? formatter(targetValue - sum, "target", {}, -1, []) : targetValue - sum}
                                    </Typography>
                                </>
                            ) : null}
                        </>
                    ) : null}

                    {/* TODO: Remove this completely if all widgets use the bar in bar target instead of gap stack. Use the old way if only a difference to target is provided */}
                    {showDiff ? (
                        <DifferenceToTarget
                            differenceToTargetPayload={differenceToTargetPayload}
                            calculatedDifferenceToTarget={calculatedDifferenceToTarget}
                            percentageTarget={percentageTarget}
                            showPercentageShare={showPercentageShares}
                            legendTypes={legendTypes}
                            formatter={formatter}
                        />
                    ) : null}
                </TooltipContentGrid>
                {isFlightPath && (
                    <>
                        <Divider sx={{ my: -0.25, mx: 1.5, gridColumn: "span 3" }} />
                        <TooltipSubHeader>
                            <SubHeaderTypography>
                                {t(translationKeys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_TOOLTIP_FLIGHTPATH)}
                            </SubHeaderTypography>
                        </TooltipSubHeader>
                        <TooltipContentGrid>
                            {sortedFlightPath.flatMap((item, index: number) => {
                                const { value, color, name, payload, stroke, strokeDasharray, strokeWidth } = item;
                                if (name == null) {
                                    // legend entry without a name is not useful
                                    return null;
                                }
                                const legendType = legendTypes?.[name] ?? "circle";
                                const LegendComponent = LegendRepresentations[legendType];
                                return [
                                    color != null && LegendComponent != null ? (
                                        <LegendComponent
                                            // name and index are not unique, but the combination should be
                                            key={`c_${name}_${index}`}
                                            color={color}
                                            stroke={stroke}
                                            strokeWidth={strokeWidth}
                                            strokeDasharray={strokeDasharray}
                                        />
                                    ) : (
                                        <div />
                                    ),
                                    <Typography key={`n_${name}_${index}`}>{name}</Typography>,
                                    value != null && payload != null ? (
                                        <Typography key={`v_${name}_${index}`} align="right">
                                            {showPercentageShares ? (
                                                <Typography key={`v_${name}_${index}`}>
                                                    {formatter ? formatter(value, name, item, index, payload) : value}
                                                </Typography>
                                            ) : null}
                                        </Typography>
                                    ) : null,
                                ];
                            })}
                        </TooltipContentGrid>
                    </>
                )}
            </Card>
        );
    },
);

export default React.memo(ChartTooltipContent);
