import { styled } from "@mui/material";
import { WidgetType } from "api-shared";
import ActivityListDepiction from "../../static/images/widgets/activitylist.svg";
import BarChartDepiction from "../../static/images/widgets/barchart.svg";
import CommentStreamDepiction from "../../static/images/widgets/commentstream.svg";
import CustomBarChartDepiction from "../../static/images/widgets/custombarchart.svg";
import FunnelChartDepiction from "../../static/images/widgets/funnel.svg";
import IdeaListDepiction from "../../static/images/widgets/idealist.svg";
import IdeaMatrixDepiction from "../../static/images/widgets/ideamatrix.svg";
import KPIWidgetDepiction from "../../static/images/widgets/kpi_widget.svg";
import LiveRunUpWidgetDepiction from "../../static/images/widgets/live-run-up.svg";
import ProcessListDepiction from "../../static/images/widgets/processlist.svg";
import RollingForecastDepiction from "../../static/images/widgets/rollingForecast.svg";
import RunupDepiction from "../../static/images/widgets/runup.svg";
import StatusAggregationDepiction from "../../static/images/widgets/status-aggregation.svg";
import TimelineDepiction from "../../static/images/widgets/timeline.svg";
import WaterfallDepiction from "../../static/images/widgets/waterfall.svg";
import WhiteSpotDepiction from "../../static/images/widgets/white-spot.svg";

interface IWidgetTemplateDepictionProps {
    type: WidgetType;
}

const depictions: Record<WidgetType, string> = {
    [WidgetType.ProcessList]: ProcessListDepiction,
    [WidgetType.CompletedEffects]: RunupDepiction,
    [WidgetType.ActivityList]: ActivityListDepiction,
    [WidgetType.CustomBarChart]: CustomBarChartDepiction,
    [WidgetType.FunnelChart]: FunnelChartDepiction,
    [WidgetType.RollingForecast]: RollingForecastDepiction,
    [WidgetType.IdeaList]: IdeaListDepiction,
    [WidgetType.IdeaMatrix]: IdeaMatrixDepiction,
    [WidgetType.ProcessWhiteSpotMatrix]: WhiteSpotDepiction,
    [WidgetType.CommentStream]: CommentStreamDepiction,
    [WidgetType.LiveRunUp]: LiveRunUpWidgetDepiction,
    [WidgetType.PotentialKPI]: KPIWidgetDepiction,
    [WidgetType.StatusAggregation]: StatusAggregationDepiction,
    [WidgetType.LegacyProjectProgress]: LiveRunUpWidgetDepiction,
    [WidgetType.Timeline]: TimelineDepiction,
    [WidgetType.WeeklySavingsRunUp]: LiveRunUpWidgetDepiction,
    [WidgetType.Waterfall]: WaterfallDepiction,
    [WidgetType.ProjectProgress]: LiveRunUpWidgetDepiction,
};

const RestrictedImage = styled("img")({
    maxWidth: "100%",
    maxHeight: "100%",
});

const WidgetTemplateDepiction = ({ type }: IWidgetTemplateDepictionProps) => {
    const path = depictions[type] ?? BarChartDepiction;
    return <RestrictedImage src={path} alt="" />;
};

export default WidgetTemplateDepiction;
