import { EMPTY_FILTER, FilterDefinition, MeasureFieldNames, Operators, PivotMetric, PotentialType, ScopeDto, Sort } from "api-shared";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import MeasuresTable from "../../../components/measures/MeasuresTable";
import { useEffectCategoryAttributes } from "../../../domain/measure-config";
import { useMeasureIds, useMeasures } from "../../../domain/measure/list";
import { ApiError } from "../../../lib/api";
import { getTranslatedErrorMessage } from "../../../lib/error";
import WidgetError from "../WidgetError";

type DrilldownValue = (string | number)[];
export type Drilldown = Record<string, DrilldownValue>;

export interface DrilldownTableProps {
    dataKey: string;
    drilldown: Drilldown;
    filter?: FilterDefinition;
    scope?: ScopeDto;
    columns?: string[];
    potentialType?: PotentialType | PivotMetric;
}

const DRILLDOWN_COLUMNS = [
    MeasureFieldNames.ClientIid,
    MeasureFieldNames.Title,
    MeasureFieldNames.CurrentGateTaskConfigId,
    MeasureFieldNames.Status,
    MeasureFieldNames.AssignedToId,
];

const DRILLDOWN_PAGE_SIZES = [25, 50, 100];

function isNullValue(value: DrilldownValue): value is ["null"] {
    return value.length === 1 && value[0] === "null";
}

const DrilldownTable = ({
    dataKey,
    drilldown,
    filter = EMPTY_FILTER,
    scope = {
        startDate: null,
        endDate: null,
        attributes: {},
    },
    columns,
    potentialType = PotentialType.Effect,
}: DrilldownTableProps) => {
    const { t } = useTranslation();
    const effectCategoryAttributes = useEffectCategoryAttributes();

    const [sorting, setSorting] = useState({ orderBy: MeasureFieldNames.ClientIid as string, sort: Sort.ASCENDING });
    const [pagination, setPagination] = useState({
        pageSize: DRILLDOWN_PAGE_SIZES[0],
        page: 0,
    });

    const updateSort = useCallback((newOrderBy: string, newSort: Sort) => {
        setSorting({ orderBy: newOrderBy, sort: newSort });
        // reset page to first page, when sorting changes
        setPagination((old) => (old.page !== 0 ? { ...old, page: 0 } : old));
    }, []);

    const updatePage = useCallback((newPageSize: number, newPage: number) => {
        setPagination({ page: newPage, pageSize: newPageSize });
    }, []);

    const drilldownFilter = [
        ...filter,
        ...Object.entries(drilldown).map(([field, value]) =>
            !isNullValue(value)
                ? {
                      field,
                      operator: Operators.In,
                      values: value,
                  }
                : {
                      field,
                      operator: Operators.NotSet,
                      values: [0],
                  },
        ),
    ];

    const effectCategoryFields = new Set(effectCategoryAttributes.map(({ title }) => title));
    const drilldownScope = {
        ...scope,
        attributes: {
            ...scope.attributes,
            ...Object.entries(drilldown)
                .filter(([, value]) => !isNullValue(value))
                .filter(([field]) => effectCategoryFields.has(field))
                .reduce(
                    (attributes, [field, value]) => ({
                        ...attributes,
                        [field]: value.map((val) => Number(val)),
                    }),
                    {} as Record<string, number[]>,
                ),
        },
    };

    // get measure ids of all measures for the drilldown attribute combination in the original scope
    const measuresQuery = useMeasureIds(`${dataKey}_ids`, {
        filter: drilldownFilter,
        scope,
        meta: {
            // ignore error notification because errors are shown inline
            ignoreErrors: true,
        },
    });

    const measureIds = measuresQuery.data ?? [];
    const measureIdsFilter = [{ field: MeasureFieldNames.ClientIid, operator: Operators.In, values: measureIds }];

    // get full measures for the drilldown table with a display ID based filter. Additionally expand the original scope if a drilldown attribute is a effect category attribute
    const measuresDrilldownQuery = useMeasures(`${dataKey}_drilldown`, {
        filter: measureIdsFilter,
        ...pagination,
        ...sorting,
        scope: drilldownScope,
        enabled: measuresQuery.isSuccess && measuresQuery.data.length > 0,
        meta: {
            // ignore error notification because errors are shown inline
            ignoreErrors: true,
        },
    });

    const defaultColumns = useMemo<string[]>(() => {
        if (potentialType === PivotMetric.MeasureId) {
            return DRILLDOWN_COLUMNS;
        }

        return [...DRILLDOWN_COLUMNS, potentialType];
    }, [potentialType]);

    const isError =
        (measuresQuery.isError && measuresQuery.error instanceof ApiError) ||
        (measuresDrilldownQuery.isError && measuresDrilldownQuery.error instanceof ApiError);
    const error = measuresQuery.error ?? measuresDrilldownQuery.error;
    const errorMessage = isError ? getTranslatedErrorMessage(error as ApiError, t) : null;

    const isFetching = !measuresQuery.isSuccess || (measuresQuery.data.length > 0 && !measuresDrilldownQuery.isSuccess);
    if (isError) {
        return <WidgetError>{errorMessage}</WidgetError>;
    }

    return (
        <MeasuresTable
            {...sorting}
            data={measuresDrilldownQuery.data}
            pageSize={pagination.pageSize}
            columns={columns ?? defaultColumns}
            onSortChanged={updateSort}
            onPageChanged={updatePage}
            pageSizeOptions={DRILLDOWN_PAGE_SIZES}
            isFetching={isFetching}
            disableResizing
            densePagination
        />
    );
};

export default DrilldownTable;
