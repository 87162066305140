import { FieldDefinitionsDto, FilterDefinition, ProjectProgressWidgetConfig } from "api-shared";
import { useTranslation } from "react-i18next";
import { useMeasureAttributes } from "../../../../domain/endpoint";
import { useNonDeletedUsers } from "../../../../domain/users";
import FilterForm from "../../../measures/preferences/filter-configuration/FilterForm";
import WidgetConfigTabContainer from "../../WidgetConfigTabContainer";

interface ProjectProgressConfigTabFilterConfigurationProps {
    config: ProjectProgressWidgetConfig;
    disabled: boolean;
    updateConfig: (newConfig: ProjectProgressWidgetConfig, overrideIsValidFilter: boolean) => void;
    setIsValidFilter: (newValue: boolean) => void;
    fieldDefinitions: FieldDefinitionsDto;
}

const ProjectProgressConfigTabFilterConfiguration = ({
    config,
    disabled,
    updateConfig,
    setIsValidFilter,
    fieldDefinitions,
}: ProjectProgressConfigTabFilterConfigurationProps) => {
    const { t } = useTranslation();
    const measureAttributes = useMeasureAttributes();
    const users = useNonDeletedUsers();

    return (
        <WidgetConfigTabContainer>
            <FilterForm
                filterDefinition={config.filter}
                onChange={(newFilter: FilterDefinition, isValid) => {
                    updateConfig({ ...config, filter: newFilter }, isValid);
                    setIsValidFilter(isValid);
                }}
                fieldDefinitions={fieldDefinitions}
                measureAttributes={measureAttributes}
                users={users}
                translate={t}
                disabled={disabled}
            />
        </WidgetConfigTabContainer>
    );
};

export default ProjectProgressConfigTabFilterConfiguration;
