import { Grid } from "@mui/material";
import { ProjectProgressWidgetConfig } from "api-shared";
import { useTranslation } from "react-i18next";
import { useCurrentClient } from "../../../../domain/client.ts";
import { useMeasureConfigs } from "../../../../domain/measure-config.ts";
import ScopeSelect from "../../../measures/preferences/ScopeSelect.tsx";
import WidgetConfigTabContainer from "../../WidgetConfigTabContainer.tsx";

interface ProjectProgressConfigTabScopeProps {
    config: ProjectProgressWidgetConfig;
    onConfigChange: (newConfig: ProjectProgressWidgetConfig, isValid?: boolean) => void;
    isValidFilter: boolean;
    disabled: boolean;
}

// Skip special customer attribute for now as this is still used for drilldown
const SPECIAL_RECURRING_ATTRIBUTE_NAME = "oneOffMeasure";
const skippedFields = [SPECIAL_RECURRING_ATTRIBUTE_NAME];

const ProjectProgressConfigTabScope = ({ config, onConfigChange, isValidFilter, disabled }: ProjectProgressConfigTabScopeProps) => {
    const { t } = useTranslation();
    const client = useCurrentClient();
    const measureConfigs = useMeasureConfigs();

    return (
        <WidgetConfigTabContainer>
            <Grid container>
                <Grid item xs={6}>
                    <ScopeSelect
                        value={config.scope}
                        onChange={(scope) =>
                            onConfigChange(
                                { ...config, scope: { ...config.scope, attributes: scope.attributes, currencyIds: scope.currencyIds } },
                                isValidFilter,
                            )
                        }
                        disabled={disabled}
                        measureConfigs={measureConfigs}
                        measureConfigIds={measureConfigs.map((measureConfig) => measureConfig.id)}
                        financialMonth={client.fiscalYear}
                        yearsBefore={client.fiscalYearRangePast}
                        yearsAfter={client.fiscalYearRangeFuture}
                        menuPortalTarget={document.body}
                        translate={t}
                        skipTimeRange
                        skipFields={skippedFields}
                    />
                </Grid>
            </Grid>
        </WidgetConfigTabContainer>
    );
};

export default ProjectProgressConfigTabScope;
