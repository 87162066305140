import WidgetConfigTabContainer from "../../WidgetConfigTabContainer";
import WidgetDescriptionField from "../../WidgetDescriptionField";

interface ProjectProgressConfigTabDescriptionProps {
    description: string | null;
    onDescriptionChange: (newValue: string) => void;
    disabled: boolean;
    label: string;
}

const ProjectProgressConfigTabDescription = ({
    description,
    onDescriptionChange,
    disabled,
    label,
}: ProjectProgressConfigTabDescriptionProps) => {
    return (
        <WidgetConfigTabContainer>
            <WidgetDescriptionField description={description} onDescriptionChange={onDescriptionChange} label={label} disabled={disabled} />
        </WidgetConfigTabContainer>
    );
};

export default ProjectProgressConfigTabDescription;
