import CancelIcon from "@mui/icons-material/CancelRounded";
import CheckmarkIcon from "@mui/icons-material/CheckCircleRounded";
import { Box, Divider, Typography, styled, useTheme } from "@mui/material";
import { Stack, alpha, hexToRgb } from "@mui/system";
import {
    BasicMeasureFilterAndScopeConfig,
    EffectFilterDateField,
    MeasureFieldNames,
    MeasureStatus,
    Operators,
    WidgetType,
    validateBasicMeasureFilterAndScopeConfig,
} from "api-shared";
import moment from "moment";
import { useState } from "react";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useMeasuresPotential } from "../../../domain/measure/potential";
import useCurrency from "../../../hooks/useCurrency";
import { translationKeys } from "../../../translations/main-translations";
import { IWidgetContentProps } from "../Widget";
import WidgetConfigDialog from "../WidgetConfigDialog";
import WidgetError from "../WidgetError";
import DrilldownDialog from "../reporting/DrilldownDialog";
import StatusAggregationWidgetConfigForm from "./StatusAggregationWidgetConfigForm";

const StatusAggregationWidgetContainer = styled(Box)(({ theme }) => ({
    height: "100%",
    padding: theme.spacing(3),
    paddingTop: 0,
}));

const GradientBorderContainer = styled(Box)(({ theme }) => ({
    padding: "1px",
    background: `linear-gradient(90deg, ${theme.palette.dashboardSeaGreen.main} 0%, ${theme.palette.dashboardRed.main} 100%), #FFF;`,
    height: "100%",
    borderRadius: theme.shape.borderRadiusLarge,
}));

const GradientBackgroundContainer = styled(Stack)(({ theme }) => ({
    height: "100%",
    borderRadius: theme.shape.borderRadiusLarge,
    background: `linear-gradient(90deg, ${alpha(hexToRgb(theme.palette.dashboardSeaGreen.main), 0.1)} 0%, ${alpha(
        hexToRgb(theme.palette.dashboardRed.main),
        0.1,
    )} 100%), #FFF;`,
}));

const StatusColumn = styled(Stack)(() => ({
    flexGrow: 1,
    flexBasis: 0,
    textAlign: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "opacity 250ms ease",
    ["&>span"]: {
        lineHeight: 1,
    },
    ["&>h6"]: {
        lineHeight: 1,
    },
    ["&:hover"]: {
        opacity: 0.65,
    },
}));

const Loader = styled(LoadingAnimation)(() => ({
    height: "100%",
}));

const BorderedCancelIcon = styled(CancelIcon)(({ theme }) => ({
    border: `1px solid ${theme.palette.dashboardRed.main}`,
    color: theme.palette.dashboardRed.main,
    borderRadius: "1em",
}));

const BorderedCheckmarkIcon = styled(CheckmarkIcon)(({ theme }) => ({
    border: `1px solid ${theme.palette.dashboardSeaGreen.main}`,
    color: theme.palette.dashboardSeaGreen.main,
    borderRadius: "1em",
}));

const StatusAggregationWidget = ({
    widget,
    isConfigDialogOpen,
    onConfigDialogClose,
    onConfigSave,
    translate,
    disabled = false,
    readOnlyLabel,
    isInView,
}: IWidgetContentProps) => {
    const [drillDownState, setDrillDownState] = useState<"delayed" | "ontrack" | false>(false);
    const { formatCurrencyShort } = useCurrency();
    const theme = useTheme();

    const { filter, scope, potentialType } = widget.config as BasicMeasureFilterAndScopeConfig;
    const date = moment().format("YYYY-MM-DD");

    // get filter for sum of delayed measures
    // this is all open measures where the start of effectiveness (calculation field startDate) is in the past
    const delayedFilter = [
        ...filter,
        { field: MeasureFieldNames.Status, values: [MeasureStatus.STATUS_OPEN], operator: Operators.In },
        { field: EffectFilterDateField.StartDate, values: ["0"], operator: Operators.Before },
    ];

    // get filters for sums of all "on track" measures
    // this is all open measures where the start of effectiveness (calculation field startDate) is in the future
    const ontrackFilter = [
        ...filter,
        { field: MeasureFieldNames.Status, values: [MeasureStatus.STATUS_OPEN], operator: Operators.In },
        { field: EffectFilterDateField.StartDate, values: [date], operator: Operators.GreaterThan },
    ];

    const ontrackMeasuresQuery = useMeasuresPotential(
        `ontrack-${widget.id}`,
        {
            filter: ontrackFilter,
            scope,
        },
        isInView,
        {
            // ignore error notification because errors are shown inline
            ignoreErrors: true,
        },
    );

    const delayedMeasuresQuery = useMeasuresPotential(
        `overdue-${widget.id}`,
        {
            filter: delayedFilter,
            scope,
        },
        isInView,
        {
            // ignore error notification because errors are shown inline
            ignoreErrors: true,
        },
    );

    if (!validateBasicMeasureFilterAndScopeConfig(widget.config)) {
        return null;
    }

    const isDataError = ontrackMeasuresQuery.isError || delayedMeasuresQuery.isError;
    const isFetching = ontrackMeasuresQuery.isFetching || delayedMeasuresQuery.isFetching;

    const ontrackSum = ontrackMeasuresQuery.data?.sums[potentialType] ?? 0;
    const ontrackCount = ontrackMeasuresQuery.data?.matchingItems ?? 0;
    const delayedSum = delayedMeasuresQuery.data?.sums[potentialType] ?? 0;
    const delayedCount = delayedMeasuresQuery.data?.matchingItems ?? 0;

    if (isFetching) {
        return <Loader />;
    }

    return (
        <>
            <WidgetConfigDialog<WidgetType.StatusAggregation>
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={onConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateBasicMeasureFilterAndScopeConfig}
                FormComponent={StatusAggregationWidgetConfigForm}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
            />

            {drillDownState !== false && (
                <DrilldownDialog
                    open
                    onClose={() => setDrillDownState(false)}
                    dataKey={`widget_${widget.id}_${drillDownState}`}
                    drilldown={{}}
                    filter={drillDownState === "delayed" ? delayedFilter : ontrackFilter}
                    scope={scope}
                    potentialType={potentialType}
                />
            )}

            {isDataError ? <WidgetError /> : null}

            {!isDataError ? (
                <StatusAggregationWidgetContainer>
                    <GradientBorderContainer>
                        <GradientBackgroundContainer p={1} spacing={1} direction="row">
                            <StatusColumn direction="column" spacing={0.5} onClick={() => setDrillDownState("ontrack")}>
                                <Typography variant="caption" pb={0.5}>
                                    <BorderedCheckmarkIcon />
                                </Typography>
                                <Typography variant="h6" color={theme.palette.dashboardGreen.dark}>
                                    {formatCurrencyShort(ontrackSum)}
                                </Typography>
                                <Typography variant="caption">
                                    {translate(translationKeys.VDLANG_DASHBOARDS_STATUS_AGGREGATION_WIDGET_ON_TRACK, {
                                        count: ontrackCount,
                                    })}
                                </Typography>
                            </StatusColumn>

                            <Divider orientation="vertical" />

                            <StatusColumn direction="column" spacing={0.5} onClick={() => setDrillDownState("delayed")}>
                                <Typography variant="caption" pb={0.5}>
                                    <BorderedCancelIcon />
                                </Typography>

                                <Typography variant="h6" color={theme.palette.dashboardRed.dark}>
                                    {formatCurrencyShort(delayedSum)}
                                </Typography>
                                <Typography variant="caption">
                                    {translate(translationKeys.VDLANG_DASHBOARDS_STATUS_AGGREGATION_WIDGET_DELAYED, {
                                        count: delayedCount,
                                    })}
                                </Typography>
                            </StatusColumn>
                        </GradientBackgroundContainer>
                    </GradientBorderContainer>
                </StatusAggregationWidgetContainer>
            ) : null}
        </>
    );
};

export default StatusAggregationWidget;
