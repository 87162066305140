import { WidgetType } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../../components/Form";
import LoadingAnimation from "../../../components/loading/LoadingAnimation.tsx";
import { useMeasureFieldDefinitionsQuery } from "../../../domain/filters.ts";
import { translationKeys } from "../../../translations/main-translations";
import { IWidgetConfigFormProps } from "../WidgetConfigDialog";
import WidgetConfigTab from "../WidgetConfigTab";
import WidgetConfigTabs from "../WidgetConfigTabs";
import WeeklySavingsRunUpConfigTabDescription from "./tabs/WeeklySavingsRunUpConfigTabDescription";
import WeeklySavingsRunUpConfigTabFilterConfiguration from "./tabs/WeeklySavingsRunUpConfigTabFilterConfiguration.tsx";
import WeeklySavingsRunUpConfigTabGeneral from "./tabs/WeeklySavingsRunUpConfigTabGeneral";

const WeeklySavingsRunUpWidget = ({
    config,
    onConfigChange,
    disabled,
    onNameChange,
    name,
    description,
    onDescriptionChange,
    onSubmit,
}: IWidgetConfigFormProps<WidgetType.WeeklySavingsRunUp>) => {
    const { t } = useTranslation();
    const [openTab, setOpenTab] = useState(0);

    const fieldDefinitionsQuery = useMeasureFieldDefinitionsQuery();

    if (!fieldDefinitionsQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    return (
        <Form onSubmit={onSubmit}>
            <WidgetConfigTabs value={openTab} onChange={(_, newValue) => setOpenTab(newValue)}>
                <WidgetConfigTab label={t(translationKeys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_TAB_GENERAL)} />
                <WidgetConfigTab label={t(translationKeys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_TAB_DESCRIPTION)} />
                <WidgetConfigTab label={t(translationKeys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_TAB_FILTER_CONFIGURATION)} />
            </WidgetConfigTabs>
            {openTab === 0 ? (
                <WeeklySavingsRunUpConfigTabGeneral
                    config={config}
                    name={name}
                    onConfigChange={onConfigChange}
                    onNameChange={onNameChange}
                    disabled={disabled}
                />
            ) : null}
            {openTab === 1 ? (
                <WeeklySavingsRunUpConfigTabDescription
                    description={description}
                    onDescriptionChange={onDescriptionChange}
                    disabled={disabled}
                    label={t(translationKeys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_TAB_DESCRIPTION)}
                />
            ) : null}
            {openTab === 2 ? (
                <WeeklySavingsRunUpConfigTabFilterConfiguration
                    config={config}
                    disabled={disabled}
                    fieldDefinitions={fieldDefinitionsQuery.data}
                    onConfigChange={onConfigChange}
                />
            ) : null}
        </Form>
    );
};

export default WeeklySavingsRunUpWidget;
