import WarningIcon from "@mui/icons-material/WarningRounded";
import { Collapse, FormControlLabel, Grid, Stack, styled, Switch, TextField, Typography, useMediaQuery } from "@mui/material";
import {
    FeatureFlags,
    FilterDefinition,
    LiveRunUpGranularity,
    LiveRunUpWidgetConfig,
    MeasureCalculationGranularity,
    WidgetType,
} from "api-shared";
import { isEqual } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Alert from "../../../components/Alert";
import FeatureFlag from "../../../components/FeatureFlag";
import Fieldset from "../../../components/Fieldset";
import Form from "../../../components/Form";
import CalculationTimerangeSelect from "../../../components/input/date/CalculationTimerangeSelect";
import CalculationTimeSelect from "../../../components/input/date/CalculationTimeSelect";
import PotentialTypeSelect from "../../../components/input/select/PotentialTypeSelect";
import Select from "../../../components/input/select/Select";
import { SimpleCurrencyInput } from "../../../components/input/SimpleCurrencyInput";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useMeasureAttributes } from "../../../domain/endpoint";
import { useMeasureFieldDefinitionsQuery } from "../../../domain/filters";
import { usePivotFields } from "../../../domain/reporting";
import { useNonDeletedUsers } from "../../../domain/users";
import { translationKeys } from "../../../translations/main-translations";
import FilterForm from "../../measures/preferences/filter-configuration/FilterForm";
import { IWidgetConfigFormProps } from "../WidgetConfigDialog";
import WidgetConfigTab from "../WidgetConfigTab";
import WidgetConfigTabs from "../WidgetConfigTabs";
import WidgetDescriptionField from "../WidgetDescriptionField";
import LiveRunUpReferenceValuesForm from "./LiveRunUpReferenceValuesForm";
import { LiveRunUpConfigFormData } from "./LiveRunUpWidget";
import { MeasureCalculationGranularityMap, MomentGranularityMap } from "./utils";

/**
 * Make sure that start/end always match the selected granularity, even if one of the three has been changed by the user
 */
function sanitizeConfig(config: LiveRunUpWidgetConfig): LiveRunUpWidgetConfig {
    const { start, end, xAxisGranularity } = config;
    const momentBoundary = MomentGranularityMap[xAxisGranularity];
    const newStart = moment.utc(start).startOf(momentBoundary);
    const newEnd = moment.utc(end).endOf(momentBoundary);
    return {
        ...config,
        start: { month: newStart.month(), year: newStart.year() },
        end: { month: newEnd.month(), year: newEnd.year() },
    };
}

const WidgetConfigTabContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(25), // fixed height of the largest tab
}));

const LiveRunUpWidgetConfigForm = ({
    config,
    disabled,
    name,
    description,
    onConfigChange,
    onDescriptionChange,
    onNameChange,
    onSubmit,
    data,
}: IWidgetConfigFormProps<WidgetType.LiveRunUp, LiveRunUpConfigFormData>) => {
    const hasDesktopHeight = useMediaQuery("(min-height:1080px)"); // used to check how date range picker should be positioned

    const [openTab, setOpenTab] = useState(0);
    const { t: translate } = useTranslation();
    const pivotFieldsQuery = usePivotFields();

    const fieldDefinitionsQuery = useMeasureFieldDefinitionsQuery();
    const measureAttributes = useMeasureAttributes();
    const users = useNonDeletedUsers();
    const [isValidFilter, setIsValidFilter] = useState(true);

    const updateConfig = (newConfig: LiveRunUpWidgetConfig, overrideIsValidFilter = isValidFilter) => {
        const sanitizedConfig = sanitizeConfig(newConfig);
        onConfigChange(sanitizedConfig, overrideIsValidFilter);
    };

    if (!pivotFieldsQuery.isSuccess) {
        return <LoadingAnimation />;
    }

    const currentConfig = {
        filter: config.filter,
        start: config.start,
        end: config.end,
        xAxisGranularity: config.xAxisGranularity,
        accumulationStart: config.accumulationStart,
    };

    const currentConfigMatchesReferenceValuesConfig =
        config.referenceValues === null ? true : isEqual(currentConfig, config.referenceValues.config);

    const granularityOptions = Object.values(LiveRunUpGranularity)
        // half year not supported yet
        .filter((g) => g !== LiveRunUpGranularity.HalfYear)
        .map((granularity) => ({
            value: granularity,
            label: translate(`${translationKeys.VDLANG_DASHBOARDS_LIVE_RUN_UP_GRANULARITY_LABELS}.${granularity}`),
        }));
    const selectedGranularityOption = granularityOptions.find((option) => option.value === config.xAxisGranularity);

    const referenceValuesFromData = data?.reduce<Record<string, number>>((acc, item) => {
        acc[item.period] = Object.values(item)
            .filter((value): value is number => typeof value === "number")
            .reduce((sum, num) => sum + num, 0);
        return acc;
    }, {});

    const onReferenceValuesSave = () => {
        updateConfig({
            ...config,
            referenceValues: {
                timestamp: new Date().toISOString(),
                data: referenceValuesFromData ?? {},
                config: {
                    filter: config.filter,
                    start: config.start,
                    end: config.end,
                    xAxisGranularity: config.xAxisGranularity,
                    accumulationStart: config.accumulationStart,
                    potentialType: config.potentialType,
                },
            },
        });
    };

    const onReferenceValuesDelete = () => {
        updateConfig({ ...config, referenceValues: null });
    };

    const updateAccumulationStart = (newDate: moment.Moment | null) => {
        const newMoment = newDate != null ? { month: newDate.month(), year: newDate.year() } : null;
        updateConfig({ ...config, accumulationStart: newMoment });
    };

    return (
        <Form onSubmit={onSubmit}>
            <WidgetConfigTabs value={openTab} onChange={(event, newValue) => setOpenTab(newValue)}>
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_GENERAL)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_DESCRIPTION)} />
                <WidgetConfigTab label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_FILTER)} />
                <WidgetConfigTab
                    label={
                        <Stack direction="row" spacing={1} alignItems="center">
                            <span>{translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_REFERENCE_VALUES)}</span>
                            {!currentConfigMatchesReferenceValuesConfig ? <WarningIcon fontSize="inherit" /> : null}
                        </Stack>
                    }
                />
            </WidgetConfigTabs>
            {openTab === 0 ? (
                <WidgetConfigTabContainer>
                    <Grid container rowSpacing={2} columnSpacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                value={name}
                                onChange={(event) => onNameChange(event.target.value)}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_NAME)}
                                margin="none"
                                disabled={disabled}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleCurrencyInput
                                value={config.target}
                                onChange={(target) => updateConfig({ ...config, target })}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_LIVE_RUN_UP_TARGET_LABEL)}
                                margin="none"
                                disabled={disabled}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleCurrencyInput
                                value={config.axisMinValue}
                                onChange={(axisMinValue) => updateConfig({ ...config, axisMinValue })}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_LIVE_RUN_UP_AXIS_MIN_VALUE)}
                                margin="none"
                                disabled={disabled}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleCurrencyInput
                                value={config.axisMaxValue}
                                onChange={(axisMaxValue) => updateConfig({ ...config, axisMaxValue })}
                                label={translate(translationKeys.VDLANG_DASHBOARDS_LIVE_RUN_UP_AXIS_MAX_VALUE)}
                                margin="none"
                                disabled={disabled}
                                fullWidth
                            />
                        </Grid>
                        <FeatureFlag feature={FeatureFlags.FEATURE_RECURRING_EFFECTS}>
                            <Grid item xs={6}>
                                <PotentialTypeSelect
                                    value={config.potentialType}
                                    onChange={(potentialType) =>
                                        potentialType != null && onConfigChange({ ...config, potentialType }, isValidFilter)
                                    }
                                    isClearable={false}
                                    margin="none"
                                    menuPortalTarget={document.body}
                                />
                            </Grid>
                        </FeatureFlag>
                        <Grid direction="row" flexWrap="nowrap" display="flex" alignItems="center" item xs={12}>
                            <Switch
                                size="small"
                                edge="start"
                                checked={config.showSums}
                                onChange={(e, checked) => onConfigChange({ ...config, showSums: checked }, isValidFilter)}
                                disabled={disabled}
                            />
                            <Typography>{translate(translationKeys.VDLANG_DASHBOARDS_LIVE_RUN_UP_SHOW_SUMS)}</Typography>
                        </Grid>
                        {config.showSums ? (
                            <Grid direction="row" flexWrap="nowrap" display="flex" alignItems="center" item xs={12}>
                                <Switch
                                    size="small"
                                    edge="start"
                                    checked={config.showReferenceValues}
                                    onChange={(_e, checked) => onConfigChange({ ...config, showReferenceValues: checked }, isValidFilter)}
                                    disabled={disabled}
                                />
                                <Typography>{translate(translationKeys.VDLANG_DASHBOARDS_LIVE_RUN_UP_SHOW_REFERENCE_VALUES)}</Typography>
                            </Grid>
                        ) : null}
                        <Grid item xs={12}>
                            <Stack component={Fieldset} spacing={1} width="50%">
                                <Typography variant="body1" component="legend" fontWeight="medium">
                                    {translate(translationKeys.VDLANG_DASHBOARDS_LIVE_RUN_UP_CUSTOMIZE_TIME_AXIS_HEADING)}
                                </Typography>
                                <Select
                                    options={granularityOptions}
                                    value={selectedGranularityOption}
                                    onChange={(option) => option != null && updateConfig({ ...config, xAxisGranularity: option.value })}
                                    label={translate(translationKeys.VDLANG_DASHBOARDS_LIVE_RUN_UP_GRANULARITY)}
                                    isDisabled={disabled}
                                    fullWidth
                                    menuPortalTarget={document.body}
                                    margin="none"
                                />
                                <CalculationTimerangeSelect
                                    fiscalYearStart={0} // only calendar units for now
                                    granularity={MeasureCalculationGranularityMap[config.xAxisGranularity]}
                                    start={moment.utc({ ...config.start, day: 1 })}
                                    end={moment.utc({ ...config.end, day: 1 })}
                                    translate={translate}
                                    disabled={disabled}
                                    onStartChanged={(start) =>
                                        updateConfig({ ...config, start: { month: start.month(), year: start.year() } })
                                    }
                                    onEndChanged={(end) => updateConfig({ ...config, end: { month: end.month(), year: end.year() } })}
                                    hasLowHeight={!hasDesktopHeight}
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size="small"
                                            edge="start"
                                            disabled={disabled}
                                            onChange={(_, checked) =>
                                                onConfigChange({
                                                    ...config,
                                                    accumulationStart: checked ? config.start : null,
                                                })
                                            }
                                            checked={config.accumulationStart != null}
                                        />
                                    }
                                    label={translate(translationKeys.VDLANG_DASHBOARDS_LIVE_RUN_UP_ACCUMULATION_START_LABEL)}
                                />
                                <Collapse in={config.accumulationStart != null}>
                                    <CalculationTimeSelect
                                        value={moment.utc(config.accumulationStart ?? config.start)}
                                        onChange={updateAccumulationStart}
                                        translate={translate}
                                        fiscalYearStart={0}
                                        disabled={disabled}
                                        granularity={MeasureCalculationGranularity.MONTH}
                                        fullWidth
                                    />
                                </Collapse>
                            </Stack>
                        </Grid>
                    </Grid>
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 1 ? (
                <WidgetConfigTabContainer>
                    <WidgetDescriptionField
                        description={description}
                        onDescriptionChange={onDescriptionChange}
                        label={translate(translationKeys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_DESCRIPTION)}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 2 && fieldDefinitionsQuery.isSuccess ? (
                <WidgetConfigTabContainer>
                    <FilterForm
                        filterDefinition={config.filter}
                        onChange={(newFilter: FilterDefinition, isValid: boolean) => {
                            updateConfig({ ...config, filter: newFilter }, isValid);
                            setIsValidFilter(isValid);
                        }}
                        fieldDefinitions={fieldDefinitionsQuery.data}
                        measureAttributes={measureAttributes}
                        users={users}
                        translate={translate}
                        disabled={disabled}
                    />
                </WidgetConfigTabContainer>
            ) : null}
            {openTab === 3 ? (
                <WidgetConfigTabContainer>
                    {!currentConfigMatchesReferenceValuesConfig ? (
                        <Alert severity="warning" sx={{ mb: 2 }}>
                            {translate(translationKeys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIG_MISMATCH_HINT)}
                        </Alert>
                    ) : null}
                    <LiveRunUpReferenceValuesForm
                        granularity={
                            config.referenceValues?.config?.xAxisGranularity ?? selectedGranularityOption?.value ?? config.xAxisGranularity
                        }
                        start={config.referenceValues?.config?.start ?? config.start}
                        end={config.referenceValues?.config?.end ?? config.end}
                        referenceValues={config.referenceValues}
                        disabled={disabled}
                        onSave={onReferenceValuesSave}
                        onDelete={onReferenceValuesDelete}
                    />
                </WidgetConfigTabContainer>
            ) : null}
        </Form>
    );
};

export default LiveRunUpWidgetConfigForm;
