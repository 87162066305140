import { Grid, Switch, TextField, Typography, styled } from "@mui/material";
import { FeatureFlags, WeeklySavingsRunUpWidgetConfig, formatDateForAPI } from "api-shared";
import moment from "moment";
import { useTranslation } from "react-i18next";
import FeatureFlag from "../../../../components/FeatureFlag.tsx";
import { SimpleCurrencyInput } from "../../../../components/input/SimpleCurrencyInput.tsx";
import DatePicker from "../../../../components/input/date/DatePicker.tsx";
import { FiscalYearSelect } from "../../../../components/input/select/FiscalYearSelect.tsx";
import PotentialTypeSelect from "../../../../components/input/select/PotentialTypeSelect.tsx";
import { useClientFiscalYear, useCurrentClient } from "../../../../domain/client.ts";
import { translationKeys } from "../../../../translations/main-translations.ts";

const WidgetConfigTabContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(3),
    minHeight: theme.spacing(25),
}));

interface WeeklySavingsRunUpConfigTabGeneralProps {
    config: WeeklySavingsRunUpWidgetConfig;
    name: string;
    onNameChange: (newName: string) => void;
    onConfigChange: (newConfig: WeeklySavingsRunUpWidgetConfig, isValid?: boolean) => void;
    disabled: boolean;
}

const WeeklySavingsRunUpConfigTabGeneral = ({
    config,
    name,
    onConfigChange,
    onNameChange,
    disabled,
}: WeeklySavingsRunUpConfigTabGeneralProps) => {
    const { t } = useTranslation();
    const fiscalYearStartMonth = useClientFiscalYear();
    const { fiscalYearRangePast, fiscalYearRangeFuture } = useCurrentClient();

    return (
        <WidgetConfigTabContainer>
            <Grid container rowSpacing={2} columnSpacing={1}>
                <Grid item xs={12}>
                    <TextField
                        value={name}
                        onChange={(event) => onNameChange(event.target.value)}
                        label={t(translationKeys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_NAME)}
                        margin="none"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <FiscalYearSelect
                        value={config.year}
                        onChange={(year) => {
                            if (year !== null) {
                                onConfigChange({ ...config, year });
                            }
                        }}
                        fiscalYearStartMonth={fiscalYearStartMonth}
                        yearsBefore={fiscalYearRangePast}
                        yearsAfter={fiscalYearRangeFuture}
                        label={t(translationKeys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_FISCAL_YEAR)}
                        menuPortalTarget={document.body}
                        margin="none"
                        fullWidth
                        isDisabled={disabled}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SimpleCurrencyInput
                        value={config.target}
                        onChange={(target) => onConfigChange({ ...config, target })}
                        label={t(translationKeys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_TARGET)}
                        margin="none"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <FeatureFlag feature={FeatureFlags.FEATURE_RECURRING_EFFECTS}>
                    <Grid item xs={6}>
                        <PotentialTypeSelect
                            value={config.potentialType}
                            onChange={(potentialType) => potentialType != null && onConfigChange({ ...config, potentialType })}
                            isClearable={false}
                            margin="none"
                            menuPortalTarget={document.body}
                        />
                    </Grid>
                </FeatureFlag>
                <Grid item xs={6} />
                <Grid flexWrap="nowrap" display="flex" alignItems="center" item xs={6}>
                    <Switch
                        size="small"
                        edge="start"
                        checked={config.referenceDate != null}
                        onChange={(_, checked) =>
                            onConfigChange({ ...config, referenceDate: checked ? moment().format("YYYY-MM-DD") : undefined })
                        }
                        disabled={disabled}
                    />
                    <Typography>{t(translationKeys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_USE_CUSTOM_REFERENCE_DATE)}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <DatePicker
                        date={config.referenceDate == null ? null : moment(config.referenceDate)}
                        onDateChange={(momentDate) =>
                            onConfigChange({ ...config, referenceDate: formatDateForAPI(momentDate) ?? undefined })
                        }
                        disabled={disabled || !config.referenceDate}
                        placeholder={t(translationKeys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_REFERENCE_DATE)}
                        isClearable
                    />
                </Grid>
                <Grid flexWrap="nowrap" display="flex" alignItems="center" item xs={6}>
                    <Switch
                        size="small"
                        edge="start"
                        checked={config.showSums}
                        onChange={(_, checked) => onConfigChange({ ...config, showSums: checked })}
                        disabled={disabled}
                    />
                    <Typography>{t(translationKeys.VDLANG_DASHBOARDS_LEGACY_PROJECT_PROGRESS_CONFIG_SHOW_SUMS)}</Typography>
                </Grid>
            </Grid>
        </WidgetConfigTabContainer>
    );
};

export default WeeklySavingsRunUpConfigTabGeneral;
