import WidgetDescriptionField from "../../WidgetDescriptionField";
import WidgetConfigTabContainer from "../../WidgetConfigTabContainer";

interface LegacyProjectProgressConfigTabDescriptionProps {
    description: string | null;
    onDescriptionChange: (newValue: string) => void;
    disabled: boolean;
    label: string;
}

const LegacyProjectProgressConfigTabDescription = ({
    description,
    onDescriptionChange,
    disabled,
    label,
}: LegacyProjectProgressConfigTabDescriptionProps) => {
    return (
        <WidgetConfigTabContainer>
            <WidgetDescriptionField description={description} onDescriptionChange={onDescriptionChange} label={label} disabled={disabled} />
        </WidgetConfigTabContainer>
    );
};

export default LegacyProjectProgressConfigTabDescription;
