import { Grid, Stack, Switch, TextField, Tooltip, Typography, styled } from "@mui/material";
import {
    AttributeType,
    FieldDefinitionsDto,
    LegacyProjectProgressWidgetConfig,
    MaxYear,
    MeasureCalculationGranularity,
    MinYear,
    nonNullable,
} from "api-shared";
import { omit } from "lodash";
import moment from "moment/moment";
import { Trans, useTranslation } from "react-i18next";
import Fieldset from "../../../../components/Fieldset";
import OverlineLabel from "../../../../components/OverlineLabel";
import InfoIcon from "../../../../components/icons/InfoIcon";
import { SimpleCurrencyInput } from "../../../../components/input/SimpleCurrencyInput";
import CalculationTimerangeSelect from "../../../../components/input/date/CalculationTimerangeSelect";
import Select from "../../../../components/input/select/Select";
import FieldTreeInput from "../../../../components/input/tree/FieldTreeInput";
import { useCurrentClient } from "../../../../domain/client";
import { useMeasureAttributes } from "../../../../domain/endpoint";
import { calendarToFiscal } from "../../../../lib/fiscal-units";
import { translationKeys } from "../../../../translations/main-translations";
import TreeProvider from "../../../TreeProvider";
import WidgetConfigTabContainer from "../../WidgetConfigTabContainer";
import { useFieldOptions } from "../../reporting/useFieldOptions";

const TooltipInfoIcon = styled(InfoIcon)(({ theme }) => ({
    color: theme.palette.action.active,
}));

interface LegacyProjectProgressConfigTabGeneralProps {
    config: LegacyProjectProgressWidgetConfig;
    name: string;
    onNameChange: (newName: string) => void;
    onConfigChange: (newConfig: LegacyProjectProgressWidgetConfig, isValid?: boolean) => void;
    disabled: boolean;
    isValidFilter: boolean;
    fields: FieldDefinitionsDto;
    fieldOptions: { label: string; value: string }[];
}

const LegacyProjectProgressConfigTabGeneral = ({
    config,
    name,
    onNameChange,
    onConfigChange,
    disabled,
    isValidFilter,
    fields,
    fieldOptions,
}: LegacyProjectProgressConfigTabGeneralProps) => {
    const { t } = useTranslation();
    const client = useCurrentClient();
    const measureAttributes = useMeasureAttributes();

    const selectedField = fieldOptions.find(({ value }) => value === config.recurringAttribute);
    const selectedAttribute = measureAttributes.find(({ title }) => title === selectedField?.value)!;
    const isTreeAttribute = selectedAttribute?.type === AttributeType.Tree;

    const fieldOptionValues =
        useFieldOptions({
            definitions: fields,
            fieldName: selectedField?.value,
            withDefaults: false,
        }) ?? [];

    const oneTimeTreeField = {
        ...selectedAttribute,
        title: t(translationKeys.VDLANG_DASHBOARDS_LEGACY_PROJECT_PROGRESS_CONFIG_ONETIME_LABEL),
        mandatory: false,
        value: selectedField?.value ?? "",
        options: selectedAttribute?.options ?? {},
        quantity: 0,
    };

    const recurringTreeField = {
        ...selectedAttribute,
        title: t(translationKeys.VDLANG_DASHBOARDS_LEGACY_PROJECT_PROGRESS_CONFIG_RECURRING_LABEL),
        mandatory: false,
        value: selectedField?.value ?? "",
        options: selectedAttribute?.options ?? {},
        quantity: 0,
    };

    const selectedOneTimeValues = config.oneTimeFieldValues
        .map((value) => fieldOptionValues.find((v) => v.value == value) ?? null)
        .filter(nonNullable);

    let selectedOneTimeValuesAsArray: number[] = [];
    if (Array.isArray(selectedOneTimeValues)) {
        selectedOneTimeValuesAsArray = selectedOneTimeValues.map(({ value }) => Number(value));
    } else if (selectedOneTimeValues != null) {
        selectedOneTimeValuesAsArray = [+selectedOneTimeValues];
    }

    const updateSelectedOneTimeValues = (newValue: number[] | number | null) => {
        onConfigChange(
            {
                ...config,
                oneTimeFieldValues: [newValue].flat().filter(nonNullable).map(String),
            },
            isValidFilter,
        );
    };

    const selectedRecurringValues = config.recurringFieldValues
        .map((value) => fieldOptionValues.find((v) => v.value == value) ?? null)
        .filter(nonNullable);

    let selectedRecurringValuesAsArray: number[] = [];
    if (Array.isArray(selectedRecurringValues)) {
        selectedRecurringValuesAsArray = selectedRecurringValues.map(({ value }) => Number(value));
    } else if (selectedRecurringValues != null) {
        selectedRecurringValuesAsArray = [+selectedRecurringValues];
    }

    const updateSelectedRecurringValues = (newValue: number[] | number | null) => {
        onConfigChange(
            {
                ...config,
                recurringFieldValues: [newValue].flat().filter(nonNullable).map(String),
            },
            isValidFilter,
        );
    };

    const updateConfig = (newConfig: LegacyProjectProgressWidgetConfig, overrideIsValidFilter = isValidFilter) => {
        onConfigChange(newConfig, overrideIsValidFilter);
    };

    return (
        <WidgetConfigTabContainer>
            <Grid container rowSpacing={2} columnSpacing={1}>
                <Grid item xs={12}>
                    <TextField
                        value={name}
                        onChange={(event) => onNameChange(event.target.value)}
                        label={t(translationKeys.VDLANG_DASHBOARDS_LEGACY_PROJECT_PROGRESS_CONFIG_NAME)}
                        margin="none"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <SimpleCurrencyInput
                        value={config.axisMinValue}
                        onChange={(axisMinValue) => updateConfig({ ...config, axisMinValue })}
                        label={t(translationKeys.VDLANG_DASHBOARDS_LEGACY_PROJECT_PROGRESS_CONFIG_AXIS_MIN_VALUE)}
                        margin="none"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <SimpleCurrencyInput
                        value={config.axisMaxValue}
                        onChange={(axisMaxValue) => updateConfig({ ...config, axisMaxValue })}
                        label={t(translationKeys.VDLANG_DASHBOARDS_LEGACY_PROJECT_PROGRESS_CONFIG_AXIS_MAX_VALUE)}
                        margin="none"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid flexWrap="nowrap" display="flex" alignItems="center" item xs={12}>
                    <Switch
                        size="small"
                        edge="start"
                        checked={config.showSums}
                        onChange={(_, checked) => onConfigChange({ ...config, showSums: checked }, isValidFilter)}
                        disabled={disabled}
                    />
                    <Typography>{t(translationKeys.VDLANG_DASHBOARDS_LEGACY_PROJECT_PROGRESS_CONFIG_SHOW_SUMS)}</Typography>
                </Grid>
                {config.showSums ? (
                    <Grid flexWrap="nowrap" display="flex" alignItems="center" item xs={12}>
                        <Switch
                            size="small"
                            edge="start"
                            checked={config.showReferenceValues}
                            onChange={(_e, checked) =>
                                onConfigChange(
                                    {
                                        ...config,
                                        showReferenceValues: checked,
                                    },
                                    isValidFilter,
                                )
                            }
                            disabled={disabled}
                        />
                        <Typography>{t(translationKeys.VDLANG_DASHBOARDS_LEGACY_PROJECT_PROGRESS_CONFIG_SHOW_REFERENCE_VALUES)}</Typography>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Stack component={Fieldset} spacing={1} width="50%">
                        <OverlineLabel>
                            {t(translationKeys.VDLANG_DASHBOARDS_LEGACY_PROJECT_PROGRESS_CONFIG_SHOW_TIME_AXIS_HEADING)}
                        </OverlineLabel>
                        <CalculationTimerangeSelect
                            fiscalYearStart={client.fiscalYear}
                            granularity={MeasureCalculationGranularity.FISCAL_YEAR}
                            start={moment.utc({ ...config.start, day: 1 })}
                            end={moment.utc({ ...config.end, day: 1 })}
                            minStart={moment.utc({ day: 1, month: 0, year: MinYear })}
                            maxStart={moment.utc({ day: 1, month: 0, year: MaxYear })}
                            maxEnd={moment.min(
                                moment.utc({ ...config.start, day: 1, month: 11 }).add(5, "years"),
                                moment.utc({ day: 1, month: 11, year: MaxYear }),
                            )}
                            translate={t}
                            disabled={disabled}
                            onStartChanged={(start) => {
                                let computedEnd = moment.utc({ ...config.end });

                                if (start.isAfter(computedEnd)) {
                                    // Set end to the same year of start if new start is after end
                                    computedEnd = start.clone();
                                } else if (computedEnd.diff(start, "years") > 5) {
                                    // If end is more than 5 years after start, set it to the maximum allowed 5 years
                                    computedEnd = start.clone().add(5, "years");
                                }

                                if (computedEnd.year() > MaxYear) {
                                    // Make sure that end is not set to a year bigger than the allowed max year
                                    computedEnd = start.clone().set({ year: MaxYear });
                                }

                                const { fiscalMoment: fiscalEnd } = calendarToFiscal(
                                    computedEnd,
                                    client.fiscalYear,
                                    MeasureCalculationGranularity.FISCAL_YEAR,
                                );

                                updateConfig({
                                    ...config,
                                    start: { month: start.month(), year: start.year() },
                                    end: { month: fiscalEnd.month(), year: fiscalEnd.year() },
                                });
                            }}
                            onEndChanged={(end) => updateConfig({ ...config, end: { month: end.month(), year: end.year() } })}
                        />
                    </Stack>
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Tooltip title={<Trans i18nKey="VDLANG_DASHBOARDS_LEGACY_PROJECT_PROGRESS_CONFIG_CONFIGURE_RECURRING_EFFECTS_HINT" />}>
                        <Typography variant="body1" component="legend" fontWeight="medium">
                            {t(translationKeys.VDLANG_DASHBOARDS_LEGACY_PROJECT_PROGRESS_CONFIG_CONFIGURE_RECURRING_EFFECTS)}{" "}
                            <TooltipInfoIcon />
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid item xs={6}>
                    <Stack gap={2}>
                        <Select
                            value={selectedField}
                            options={fieldOptions}
                            onChange={(option) =>
                                option != null &&
                                onConfigChange(
                                    {
                                        ...config,
                                        recurringAttribute: option.value,
                                        recurringFieldValues: [],
                                        oneTimeFieldValues: [],
                                        scope: {
                                            ...config.scope,
                                            attributes: omit(config.scope.attributes, option.value),
                                        },
                                    },
                                    isValidFilter,
                                )
                            }
                            label={t(translationKeys.VDLANG_DASHBOARDS_LEGACY_PROJECT_PROGRESS_CONFIG_ATTRIBUTE_LABEL)}
                            menuPortalTarget={document.body}
                            margin="none"
                            fullWidth
                            isMulti={false}
                            isDisabled={disabled}
                        />
                        {isTreeAttribute ? (
                            <>
                                <TreeProvider
                                    field={oneTimeTreeField}
                                    component={FieldTreeInput}
                                    componentProps={{
                                        field: oneTimeTreeField,
                                        translate: t,
                                        updateValue: updateSelectedOneTimeValues,
                                        isMulti: true,
                                        value: selectedOneTimeValuesAsArray,
                                        label: oneTimeTreeField.title,
                                        disabled,
                                        narrowSelection: false,
                                    }}
                                />
                                <TreeProvider
                                    field={recurringTreeField}
                                    component={FieldTreeInput}
                                    componentProps={{
                                        field: recurringTreeField,
                                        translate: t,
                                        updateValue: updateSelectedRecurringValues,
                                        isMulti: true,
                                        value: selectedRecurringValuesAsArray,
                                        label: recurringTreeField.title,
                                        disabled,
                                        narrowSelection: false,
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <Select
                                    value={selectedOneTimeValues}
                                    options={fieldOptionValues}
                                    onChange={(option) =>
                                        option != null &&
                                        onConfigChange({ ...config, oneTimeFieldValues: option.map((v) => v.value) }, isValidFilter)
                                    }
                                    label={t(translationKeys.VDLANG_DASHBOARDS_LEGACY_PROJECT_PROGRESS_CONFIG_ONETIME_LABEL)}
                                    menuPortalTarget={document.body}
                                    margin="none"
                                    fullWidth
                                    isMulti
                                    isSearchable
                                    isDisabled={disabled}
                                />
                                <Select
                                    value={selectedRecurringValues}
                                    options={fieldOptionValues}
                                    onChange={(option) =>
                                        option != null &&
                                        onConfigChange({ ...config, recurringFieldValues: option.map((v) => v.value) }, isValidFilter)
                                    }
                                    label={t(translationKeys.VDLANG_DASHBOARDS_LEGACY_PROJECT_PROGRESS_CONFIG_RECURRING_LABEL)}
                                    menuPortalTarget={document.body}
                                    margin="none"
                                    fullWidth
                                    isMulti
                                    isSearchable
                                    isDisabled={disabled}
                                />
                            </>
                        )}
                    </Stack>
                </Grid>
            </Grid>
        </WidgetConfigTabContainer>
    );
};

export default LegacyProjectProgressConfigTabGeneral;
