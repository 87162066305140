import { FormLabel, Grid, styled } from "@mui/material";

import { AclNamespaces, AclPermissions, validateActivityListWidgetConfig, WidgetType } from "api-shared";
import { TFunction } from "i18next";
import { useUserHasPermissionQuery } from "../../domain/permissions";
import { useCurrentUserId } from "../../domain/users";
import { translationKeys } from "../../translations/main-translations";
import ActivitiesShowSelect from "../tasks/preferences/ActivitiesShowSelect";
import ActivitiesUserSelect from "../tasks/preferences/ActivitiesUserSelect";
import { IWidgetConfigFormProps } from "./WidgetConfigDialog";

interface IActivityListWidgetUserSelect
    extends Omit<
        IWidgetConfigFormProps<WidgetType.ActivityList>,
        "onSubmit" | "onNameChange" | "name" | "description" | "onDescriptionChange"
    > {
    translate: TFunction;
}

const PrimaryFormLabel = styled(FormLabel)(({ theme }) => ({
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
}));

const NoMarginActivitiesUserSelect = styled(ActivitiesUserSelect)({
    marginTop: 0,
    marginBottom: 0,
});

const ActivityListWidgetUserSelect = ({ config, onConfigChange, disabled, translate }: IActivityListWidgetUserSelect) => {
    const hasPersonFilterPermissionQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.User,
        permission: AclPermissions.Filter,
        fact: {},
    });

    const currentUserId = useCurrentUserId();

    if (!validateActivityListWidgetConfig(config) || !hasPersonFilterPermissionQuery.isSuccess) {
        return null;
    }
    const activityLabel = <PrimaryFormLabel>{translate(translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_LABEL)}</PrimaryFormLabel>;

    return hasPersonFilterPermissionQuery.data.hasPermission ? (
        <>
            <Grid item xs={12} mb={2}>
                {activityLabel}
            </Grid>
            <Grid item xs={6} sx={{ pt: "0 !important" }}>
                <NoMarginActivitiesUserSelect
                    value={config.assignedToIds ?? []}
                    translate={translate}
                    onChange={(assignedToIds) =>
                        assignedToIds != null &&
                        onConfigChange({
                            ...config,
                            assignedToIds: [...assignedToIds],
                        })
                    }
                    label={translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_ASSIGNED_TO_TITLE}
                    hasUnassignedUser={true}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={6} sx={{ pt: "0 !important" }}>
                <NoMarginActivitiesUserSelect
                    value={config.createdByIds ?? []}
                    translate={translate}
                    onChange={(createdByIds) =>
                        createdByIds != null &&
                        onConfigChange({
                            ...config,
                            createdByIds: [...createdByIds],
                        })
                    }
                    label={translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_CREATED_BY_TITLE}
                    hasUnassignedUser={false}
                    disabled={disabled}
                />
            </Grid>
        </>
    ) : (
        <Grid item xs={12}>
            {currentUserId !== null ? (
                <ActivitiesShowSelect
                    margin="none"
                    menuPortalTarget={document.body}
                    currentUserId={currentUserId}
                    value={config}
                    translate={translate}
                    isDisabled={disabled}
                    onChange={(configUpdate) =>
                        configUpdate != null &&
                        onConfigChange({
                            ...config,
                            ...configUpdate,
                        })
                    }
                    labelComponent={activityLabel}
                />
            ) : null}
        </Grid>
    );
};

export default ActivityListWidgetUserSelect;
