import { Grid, Stack, Switch, TextField, Typography, useMediaQuery } from "@mui/material";
import {
    FeatureFlags,
    formatDateForAPI,
    getFiscalYearRange,
    MaxYear,
    MeasureCalculationGranularity,
    MinYear,
    ProjectProgressWidgetConfig,
} from "api-shared";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import FeatureFlag from "../../../../components/FeatureFlag";
import Fieldset from "../../../../components/Fieldset";
import OverlineLabel from "../../../../components/OverlineLabel";
import { SimpleCurrencyInput } from "../../../../components/input/SimpleCurrencyInput";
import CalculationTimerangeSelect from "../../../../components/input/date/CalculationTimerangeSelect";
import PotentialTypeSelect from "../../../../components/input/select/PotentialTypeSelect";
import { useCurrentClient } from "../../../../domain/client";
import { translationKeys } from "../../../../translations/main-translations";
import WidgetConfigTabContainer from "../../WidgetConfigTabContainer";

interface ProjectProgressConfigTabGeneralProps {
    config: ProjectProgressWidgetConfig;
    name: string;
    onNameChange: (newName: string) => void;
    onConfigChange: (newConfig: ProjectProgressWidgetConfig, isValid?: boolean) => void;
    disabled: boolean;
    isValidFilter: boolean;
}

const ProjectProgressConfigTabGeneral = ({
    config,
    name,
    onNameChange,
    onConfigChange,
    disabled,
    isValidFilter,
}: ProjectProgressConfigTabGeneralProps) => {
    const hasDesktopHeight = useMediaQuery("(min-height:1080px)"); // used to check how date range picker should be positioned

    const { t } = useTranslation();
    const client = useCurrentClient();

    const currentStartMoment = moment.utc(config.scope.startDate);
    const currentEndMoment = moment.utc(config.scope.endDate);

    const maxEnd = moment.min(
        getFiscalYearRange(currentStartMoment.clone().add(5, "years"), client.fiscalYear)[1],
        moment.utc({ day: 31, month: 11, year: MaxYear }),
    );

    const updateConfig = (newConfig: ProjectProgressWidgetConfig, overrideIsValidFilter = isValidFilter) => {
        onConfigChange(newConfig, overrideIsValidFilter);
    };

    function handleStartDateChange(start: moment.Moment) {
        let currentEndDate = currentEndMoment.clone();

        if (start.isAfter(currentEndDate)) {
            // Set end to the same year of start if new start is after end
            const [, endOfStartFiscalYear] = getFiscalYearRange(start.clone(), client.fiscalYear);
            currentEndDate = endOfStartFiscalYear;
        } else if (currentEndDate.diff(start, "years") > 5) {
            // If end is more than 5 years after start, set it to the maximum allowed 5 years
            const [, endOfMaxYearRange] = getFiscalYearRange(start.clone().add(5, "years"), client.fiscalYear);
            currentEndDate = endOfMaxYearRange;
        }

        if (currentEndDate.year() > MaxYear) {
            // Make sure that end is not set to a year bigger than the allowed max year
            currentEndDate = start.clone().set({ year: MaxYear });
        }

        updateConfig({
            ...config,
            scope: {
                ...config.scope,
                startDate: formatDateForAPI(start),
                endDate: formatDateForAPI(currentEndDate),
            },
        });
    }

    function handleEndDateChange(end: moment.Moment) {
        const [, rangeEnd] = getFiscalYearRange(end, client.fiscalYear);
        updateConfig({ ...config, scope: { ...config.scope, endDate: formatDateForAPI(rangeEnd) } });
    }

    return (
        <WidgetConfigTabContainer>
            <Grid container rowSpacing={2} columnSpacing={1}>
                <Grid item xs={12}>
                    <TextField
                        value={name}
                        onChange={(event) => onNameChange(event.target.value)}
                        label={t(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_NAME)}
                        margin="none"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <SimpleCurrencyInput
                        value={config.axisMinValue}
                        onChange={(axisMinValue) => updateConfig({ ...config, axisMinValue })}
                        label={t(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_AXIS_MIN_VALUE)}
                        margin="none"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <SimpleCurrencyInput
                        value={config.axisMaxValue}
                        onChange={(axisMaxValue) => updateConfig({ ...config, axisMaxValue })}
                        label={t(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_AXIS_MAX_VALUE)}
                        margin="none"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <FeatureFlag feature={FeatureFlags.FEATURE_RECURRING_EFFECTS}>
                    <Grid item xs={6}>
                        <PotentialTypeSelect
                            value={config.potentialType}
                            onChange={(potentialType) =>
                                potentialType != null && onConfigChange({ ...config, potentialType }, isValidFilter)
                            }
                            isClearable={false}
                            margin="none"
                            menuPortalTarget={document.body}
                        />
                    </Grid>
                </FeatureFlag>
                <Grid flexWrap="nowrap" display="flex" alignItems="center" item xs={12}>
                    <Switch
                        size="small"
                        edge="start"
                        checked={config.showSums}
                        onChange={(_, checked) => onConfigChange({ ...config, showSums: checked }, isValidFilter)}
                        disabled={disabled}
                    />
                    <Typography>{t(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_SHOW_SUMS)}</Typography>
                </Grid>
                {config.showSums ? (
                    <Grid flexWrap="nowrap" display="flex" alignItems="center" item xs={12}>
                        <Switch
                            size="small"
                            edge="start"
                            checked={config.showReferenceValues}
                            onChange={(_e, checked) => onConfigChange({ ...config, showReferenceValues: checked }, isValidFilter)}
                            disabled={disabled}
                        />
                        <Typography>{t(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_SHOW_REFERENCE_VALUES)}</Typography>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Stack component={Fieldset} spacing={1} width="50%">
                        <OverlineLabel>{t(translationKeys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_SHOW_TIME_AXIS_HEADING)}</OverlineLabel>
                        <CalculationTimerangeSelect
                            fiscalYearStart={client.fiscalYear}
                            granularity={MeasureCalculationGranularity.FISCAL_YEAR}
                            start={currentStartMoment}
                            end={currentEndMoment}
                            minStart={moment.utc({ day: 1, month: 0, year: MinYear })}
                            maxStart={moment.utc({ day: 31, month: 11, year: MaxYear })}
                            maxEnd={maxEnd}
                            translate={t}
                            disabled={disabled}
                            onStartChanged={handleStartDateChange}
                            onEndChanged={handleEndDateChange}
                            hasLowHeight={!hasDesktopHeight}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </WidgetConfigTabContainer>
    );
};

export default ProjectProgressConfigTabGeneral;
