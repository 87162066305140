import { PotentialType } from "api-shared";
import { useTranslation } from "react-i18next";
import { translationKeys } from "../../../translations/main-translations";
import Select from "./Select";
import { ISelectProps, Option } from "./types";

interface PotentialTypeSelectProps extends Omit<ISelectProps<Option<PotentialType>>, "onChange" | "value"> {
    onChange: (newValue: PotentialType | null) => void;
    value: PotentialType | null;
    disabled?: boolean;
}

const PotentialTypeSelect = ({ value, onChange, disabled, ...selectProps }: PotentialTypeSelectProps) => {
    const { t: translate } = useTranslation();

    const options: Option<PotentialType>[] = Object.values(PotentialType).map((type) => ({
        label: translate(`${translationKeys.VDLANG_POTENTIAL_TYPE}.${type}`),
        value: type,
    }));

    const selectedOption = options.find((o) => o.value === value) ?? null;

    return (
        <Select
            label={translate(translationKeys.VDLANG_POTENTIAL_TYPE_LABEL)}
            isMulti={false} // no support for multi selection needed yet
            options={options}
            value={selectedOption}
            onChange={(option) => onChange(option?.value ?? null)}
            placeholder={translate(translationKeys.VDLANG_SELECT_PLEASE_SELECT)}
            isDisabled={disabled}
            {...selectProps}
        />
    );
};

export default PotentialTypeSelect;
