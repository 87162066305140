import {
    FiscalBucketIdentifier,
    FiscalGranularity,
    MeasureFieldNames,
    Operators,
    ProjectProgressBarListDto,
    ProjectProgressWidgetConfig,
    validateProjectProgressWidgetConfig,
    WidgetType,
} from "api-shared";
import { isEqual } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import VerticalBarChartSkeleton from "../../../components/loading/VerticalBarChartSkeleton";
import { usePivotFields, useProjectProgressData } from "../../../domain/reporting";
import { useFilterValidation } from "../../../hooks/useFilterValidation";
import EmptyVerticalBarChartIllustration from "../../../static/images/widgets/empty-widget-bar.svg";
import { IWidgetContentProps } from "../Widget";
import WidgetConfigDialog from "../WidgetConfigDialog";
import WidgetNoData from "../WidgetNoData";
import ChartWidgetRoot from "../reporting/ChartWidgetRoot";
import type { Drilldown } from "../reporting/DrilldownTable";
import { useFieldOptions } from "../reporting/useFieldOptions";
import WidgetStateContainer from "../widget-states/WidgetStateContainer";
import ProjectProgressDrilldownDialog from "./ProjectProgressDrilldownDialog";
import ProjectProgressRunUpChart from "./ProjectProgressRunUpChart";
import ProjectProgressWidgetConfigForm from "./ProjectProgressWidgetConfigForm";

function enrichDataWithTarget(
    data: ProjectProgressBarListDto | undefined,
    targets: ProjectProgressWidgetConfig["targets"],
): (ProjectProgressBarListDto & { target?: number }) | null {
    if (data == null) {
        return null;
    }

    return targets != null
        ? data.map((item) => {
              const target = targets[item.fiscalBucketIdentifier];
              return target != null
                  ? {
                        ...item,
                        target,
                    }
                  : item;
          })
        : data;
}

const fieldName = MeasureFieldNames.CurrentGateTaskConfigId;

const SPECIAL_RECURRING_ATTRIBUTE_NAME = "oneOffMeasure";
const SPECIAL_RECURRING_CUSTOM_VALUE_ID_RECURRING = 5782;
const SPECIAL_RECURRING_CUSTOM_VALUE_ID_ONEOFF = 5781;

const ProjectProgressWidget = ({
    widget,
    isConfigDialogOpen,
    onConfigDialogClose,
    onConfigSave,
    disabled,
    readOnlyLabel,
    openConfigDialog,
    isInView,
}: IWidgetContentProps) => {
    const { t: translate } = useTranslation();

    const { targets, scope, filter, potentialType, ...config } = widget.config as ProjectProgressWidgetConfig;

    const { validate } = useFilterValidation(isInView);
    const hasValidFilter = validate?.(filter);

    const [drillDown, setDrillDown] = useState<Drilldown>();
    const [drillDownFiscalBucket, setDrillDownFiscalBucket] = useState<FiscalBucketIdentifier>();

    const projectProgressQuery = useProjectProgressData(
        {
            filter,
            scope,
            // granularity is static for now until we want to support other granularity options in this widget
            granularity: FiscalGranularity.Year,
            potentialType,
        },
        isInView,
    );

    const pivotFieldsQuery = usePivotFields(isInView);
    const stackingGroups =
        useFieldOptions({
            definitions: pivotFieldsQuery.data,
            fieldName,
            // avoid level name being suffixed with process names
            customizeField: (field) => ({ ...field, options: { ...field.options, resolveDuplicates: false } }),
        }) ?? [];

    // inverse the order of  gate task groups to display the last gate task ("completed") nearest to the 0-line of the chart
    const orderedGroups = stackingGroups.toReversed();

    const dataWithTarget = enrichDataWithTarget(projectProgressQuery.data, targets);

    function openDrillDown(xValue: string, stackingValue: unknown) {
        // scope: From config.accumulationStart -> xValue.endOfPeriod
        setDrillDown({ [fieldName]: Array.isArray(stackingValue) ? stackingValue : [stackingValue] });
        // xValue holds the first day of the clicked (fiscal) year range
        setDrillDownFiscalBucket(FiscalBucketIdentifier.fromString(xValue));
    }

    const currentConfig = {
        filter,
        scope,
        potentialType,
    };
    const currentConfigMatchesReferenceValuesConfig =
        config.referenceValues === null ? true : isEqual(currentConfig, config.referenceValues.config);

    return (
        <ChartWidgetRoot>
            <WidgetConfigDialog<WidgetType.ProjectProgress, ProjectProgressBarListDto>
                open={isConfigDialogOpen}
                onClose={onConfigDialogClose}
                onSave={onConfigSave}
                translate={translate}
                widget={widget}
                validateConfig={validateProjectProgressWidgetConfig}
                FormComponent={ProjectProgressWidgetConfigForm}
                noPadding
                disabled={disabled}
                readOnlyLabel={readOnlyLabel}
                data={projectProgressQuery.data ?? []}
            />
            <WidgetStateContainer
                widgetType={widget.type}
                // config validation is async, so explicitly check for false to avoid invalid config state being shortly shown while validation is running
                hasInvalidConfig={hasValidFilter === false}
                dataQuery={projectProgressQuery}
                additionalQueries={[pivotFieldsQuery]}
                renderEmpty={() => <WidgetNoData src={EmptyVerticalBarChartIllustration} />}
                renderLoading={() => <VerticalBarChartSkeleton />}
                openConfigDialog={openConfigDialog}
                hasIncompleteConfig={false}
            >
                {drillDown !== undefined && drillDownFiscalBucket !== undefined ? (
                    // Drilldown will temporarily use the special customer attribute for scoping as we have currently no scope for the recurringType of effect categories
                    <ProjectProgressDrilldownDialog
                        open={drillDown !== undefined}
                        onClose={() => setDrillDown(undefined)}
                        dataKey={`widget${widget.id}`}
                        drilldown={drillDown}
                        filter={filter}
                        scope={scope}
                        selectedFiscalBucket={drillDownFiscalBucket}
                        oneTimeFilter={{
                            values: [SPECIAL_RECURRING_CUSTOM_VALUE_ID_ONEOFF],
                            operator: Operators.In,
                            field: SPECIAL_RECURRING_ATTRIBUTE_NAME,
                        }}
                        recurringFilter={{
                            values: [SPECIAL_RECURRING_CUSTOM_VALUE_ID_RECURRING],
                            operator: Operators.In,
                            field: SPECIAL_RECURRING_ATTRIBUTE_NAME,
                        }}
                        recurringAttribute={SPECIAL_RECURRING_ATTRIBUTE_NAME}
                        potentialType={potentialType}
                    />
                ) : null}
                <ProjectProgressRunUpChart
                    data={dataWithTarget ?? []}
                    xAxis="fiscalBucketIdentifier"
                    groups={orderedGroups}
                    showSums={config.showSums}
                    axisMinValue={config.axisMinValue}
                    axisMaxValue={config.axisMaxValue}
                    showReferenceValues={currentConfigMatchesReferenceValuesConfig && config.showReferenceValues}
                    referenceValues={config.referenceValues}
                    onOpenDrilldown={openDrillDown}
                />
            </WidgetStateContainer>
        </ChartWidgetRoot>
    );
};

export default ProjectProgressWidget;
