import { Paper, styled, Typography, useTheme } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { DashboardDto, DashboardLayout, WidgetType } from "api-shared";
import { isMatch } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import ReactGridLayout, { Layout, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { useTranslation } from "react-i18next";
import "react-resizable/css/styles.css";
import { useDashboardWidgets } from "../../domain/dashboards";
import { useMounted } from "../../hooks/useMounted";
import { stripPxSuffix } from "../../styles/utils";
import { translationKeys } from "../../translations/main-translations";
import DashboardResizeHandle from "./DashboardResizeHandle";
import DashboardSkeleton from "./DashboardSkeleton";
import DashboardUnderlayContainer from "./DashboardUnderlayContainer";
import { findParentWidget, GRID_COLUMNS, ROW_HEIGHT_IN_SPACING_UNITS, usePlaceholders } from "./utils";
import Widget, { widgetClasses } from "./Widget";

const dashboardClasses = {
    resizeHandle: "VdDashboard-resizeHandle",
};

interface IDashboardProps {
    dashboard: DashboardDto;
    onLayoutChanged: (newLayout: DashboardLayout) => void;
    disabled?: boolean;
}

const GridLayout = WidthProvider(ReactGridLayout);

const Root = styled("div")(({ theme }) => ({
    position: "relative", // allow absolute positioning of UnderLayContainer child
    "& .react-grid-item.react-grid-placeholder": {
        // allow hover events to be triggered although the placeholder is on top of the widget
        pointerEvents: "none",
        // customize appearance of the preview overlay
        backgroundColor: theme.palette.primary.light,
        // align with large border radius of Paper
        borderRadius: theme.shape.borderRadiusLarge,
    },
}));

const PlaceHolderWidget = styled(Paper)(({ theme }) => ({
    background: blueGrey[50],
    border: `1px dashed ${blueGrey[200]}`,
}));

const NoWidgetParent = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
}));

const NoWidgetMessage = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1.25, 1.5),
    position: "absolute",
    marginTop: theme.spacing(37.5),
}));

const WidgetWrapper = styled("div", {
    shouldForwardProp: (prop) => prop !== "active",
})<{
    active?: boolean;
}>(({ theme, active = false }) => ({
    [`& .${dashboardClasses.resizeHandle}`]: {
        opacity: active ? 1 : 0,
        transition: theme.transitions.create("opacity"),
    },
    [`&:hover .${dashboardClasses.resizeHandle}`]: {
        opacity: 1,
    },
}));

type GridSize = {
    width: number;
    height: number;
};

const DEFAULT_MINIMAL_GRID_SIZE = { width: 2, height: 2 };
const WidgetMinimalGridSizes: Record<WidgetType, GridSize> = {
    [WidgetType.ProcessList]: DEFAULT_MINIMAL_GRID_SIZE,
    [WidgetType.CompletedEffects]: DEFAULT_MINIMAL_GRID_SIZE,
    [WidgetType.ActivityList]: DEFAULT_MINIMAL_GRID_SIZE,
    [WidgetType.CustomBarChart]: DEFAULT_MINIMAL_GRID_SIZE,
    [WidgetType.FunnelChart]: DEFAULT_MINIMAL_GRID_SIZE,
    [WidgetType.RollingForecast]: DEFAULT_MINIMAL_GRID_SIZE,
    [WidgetType.IdeaList]: DEFAULT_MINIMAL_GRID_SIZE,
    [WidgetType.IdeaMatrix]: DEFAULT_MINIMAL_GRID_SIZE,
    [WidgetType.ProcessWhiteSpotMatrix]: DEFAULT_MINIMAL_GRID_SIZE,
    [WidgetType.CommentStream]: DEFAULT_MINIMAL_GRID_SIZE,
    [WidgetType.LiveRunUp]: DEFAULT_MINIMAL_GRID_SIZE,
    [WidgetType.StatusAggregation]: { width: 2, height: 1 },
    [WidgetType.PotentialKPI]: { width: 1, height: 1 },
    [WidgetType.LegacyProjectProgress]: DEFAULT_MINIMAL_GRID_SIZE,
    [WidgetType.Timeline]: DEFAULT_MINIMAL_GRID_SIZE,
    [WidgetType.WeeklySavingsRunUp]: DEFAULT_MINIMAL_GRID_SIZE,
    [WidgetType.Waterfall]: DEFAULT_MINIMAL_GRID_SIZE,
    [WidgetType.ProjectProgress]: DEFAULT_MINIMAL_GRID_SIZE,
};

const Dashboard = ({ dashboard, onLayoutChanged, disabled = false }: IDashboardProps) => {
    const widgetsQuery = useDashboardWidgets(dashboard.id);
    const hasWidget = widgetsQuery.isSuccess && widgetsQuery.data.length > 0;

    const { t: translate } = useTranslation();

    const theme = useTheme();
    const gap = stripPxSuffix(theme.spacing(2));
    const margin = useMemo(() => [gap, gap] as [number, number], [gap]);

    const [selectedWidget, setSelectedWidget] = useState<string | null>(null);

    const rowHeight = gap * ROW_HEIGHT_IN_SPACING_UNITS;

    const { updatePlaceholderRows, placeholders, updatePlaceholderWidth } = usePlaceholders({
        isDragging: selectedWidget !== null,
        widgets: widgetsQuery.data ?? [],
        gap,
        rowHeight,
    });

    const resetSelection = (layout: Layout[]) => {
        setSelectedWidget(null);
        // update current number of rows
        updatePlaceholderRows(layout);
    };

    const updateSelection = (
        layout: Layout[],
        oldItem: Layout,
        newItem: Layout,
        placeholder: Layout,
        event: MouseEvent,
        element: HTMLElement,
    ) => {
        setSelectedWidget(oldItem.i);
        updatePlaceholderRows(layout);

        // This callback is used for both move and resize operations. In case of resizing, the element will be the resize handle instead of
        // the widget's DOM element, so it is needed to resolve to find the actual widget element first
        const widgetDOMElement = findParentWidget(element);
        if (widgetDOMElement != null) {
            updatePlaceholderWidth(widgetDOMElement.clientWidth, newItem);
        }
    };

    const handleLayoutChange = (newLayout: ReactGridLayout.Layout[]) => {
        const hasChangedLocation = widgetsQuery.data?.some(({ id, location }) => {
            const newLocation = newLayout.find((l) => l.i === String(id));
            // newLocation contains additional data, so use isMatch instead of isEqual here for comparison
            return newLocation === undefined || !isMatch(newLocation, location);
        });

        if (newLayout.length === widgetsQuery.data?.length && !hasChangedLocation) {
            // the "new" layout is already persisted in the widget's locations, so not need to save it
            return;
        }

        const updatedLayout = newLayout.map(({ i, x, y, w, h }) => ({
            widgetId: +i,
            location: { x, y, w, h },
        }));
        updatePlaceholderRows(newLayout);
        onLayoutChanged(updatedLayout);
    };

    // This is the recommended way to improve performance
    // Check later with full widgets if this is sufficient and working properly
    // https://github.com/react-grid-layout/react-grid-layout#performance
    const children = useMemo(
        () =>
            widgetsQuery.data?.map((widget) => {
                // the layouts provided to the grid seem to be only used as initial starting point, any changes will be ignored by the GridLayout
                // It's important to already provide the correct layouts on first render
                const minimalWidgetSize = WidgetMinimalGridSizes[widget.type];
                const location = {
                    ...widget.location,
                    i: String(widget.id), // library needs i to be a string
                    minW: minimalWidgetSize.width,
                    minH: minimalWidgetSize.height,
                };
                return (
                    <WidgetWrapper
                        key={widget.id}
                        data-grid={location}
                        active={location.i === selectedWidget}
                        // Some edge cases will only trigger onDragStart and leave the widget selected afterwards
                        // make sure to always reset selection when mouse/touch device are lifted
                        onTouchEnd={() => setSelectedWidget(null)}
                        onMouseUp={() => setSelectedWidget(null)}
                        onClick={() => setSelectedWidget(null)}
                    >
                        <Widget
                            isSelected={location.i === selectedWidget}
                            widget={widget}
                            disabled={disabled}
                            dashboardOwnerId={dashboard.userId}
                        />
                    </WidgetWrapper>
                );
            }),
        [widgetsQuery.data, dashboard.userId, selectedWidget, disabled],
    );

    // Keep track lazily of mounted state with a delay of 1s
    const mounted = useMounted(1000);

    // react-grid-layout's shouldComponentUpdate uses deepEqual to compare its props, including any react elements. Those sometimes contain
    // circular references, causing infinite recursion. Avoid this by stabilizing the reference. Use useRef instead of useMemo because the
    // latter does not fully guarantee stable references.
    const handleRef = useRef(<DashboardResizeHandle className={dashboardClasses.resizeHandle} />);

    if (widgetsQuery.isLoading) {
        return <DashboardSkeleton />;
    }

    return (
        <Root>
            <GridLayout
                draggableHandle={!disabled ? `.${widgetClasses.dragHandle}` : undefined}
                cols={GRID_COLUMNS}
                rowHeight={rowHeight}
                margin={margin}
                onDragStart={updateSelection}
                onDrag={updatePlaceholderRows}
                onDragStop={resetSelection}
                onResizeStart={updateSelection}
                onResize={updatePlaceholderRows}
                onResizeStop={resetSelection}
                resizeHandle={!disabled ? handleRef.current : null}
                onLayoutChange={handleLayoutChange}
                isDraggable={!disabled}
                isResizable={!disabled}
                // Enable more performant resizing with CSS only after component has mounted
                // When enabled on first render, widgets would be animated from (0,0) into their final position, making the
                // IntersectionObserver trigger too early, resulting in all queries to be executed immediately
                useCSSTransforms={mounted}
            >
                {children}
            </GridLayout>
            <DashboardUnderlayContainer rowHeight={rowHeight} show={selectedWidget !== null || !hasWidget}>
                {placeholders.map(({ key, ...placeholderProps }) => (
                    <PlaceHolderWidget key={key} {...placeholderProps} />
                ))}
            </DashboardUnderlayContainer>
            {!hasWidget && (
                <NoWidgetParent>
                    <NoWidgetMessage>
                        <Typography align="center" variant="body2" color="textSecondary">
                            {translate(translationKeys.VDLANG_DASHBOARD_EMPTY_WIDGET)}
                        </Typography>
                    </NoWidgetMessage>
                </NoWidgetParent>
            )}
        </Root>
    );
};

export default React.memo(Dashboard);
