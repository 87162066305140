import { Grid, List, ListItem, Stack, styled, Typography } from "@mui/material";
import { CreateWidgetRequestBodyDto, WidgetTemplateDto, WidgetType } from "api-shared";
import { TFunction } from "i18next";
import { sortBy } from "lodash";
import { useState, type MouseEvent } from "react";
import Form from "../../components/Form.tsx";
import SearchInput from "../../components/input/SearchInput.tsx";
import SidebarNavItemButton from "../../components/sidebar/SidebarNavItemButton";
import { translationKeys } from "../../translations/main-translations.ts";
import WidgetTemplate from "./WidgetTemplate";

interface WidgetTemplateDialogContentProps {
    widgetTemplates: WidgetTemplateDto[];
    addDashboardWidget: (data: CreateWidgetRequestBodyDto) => void;
    translate: TFunction;
}

type WidgetTemplateCategory = WidgetType | null | "CustomerSpecific";

const NoWrapListItemButton = styled(SidebarNavItemButton)({ whiteSpace: "nowrap" });

const Sidebar = styled(Stack)(({ theme }) => ({
    overflowY: "auto",
    overflowX: "hidden",
    padding: theme.spacing(2, 1, 0),
    minWidth: theme.spacing(31),
}));

const TemplateContainer = styled(Grid)(() => ({
    overflowY: "scroll",
}));

const TemplateGrid = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(1, 3, 0, 2),
    margin: 0,
    height: "fit-content",
}));

const getTabs = (translate: TFunction) => {
    const tabs: { type: WidgetTemplateCategory; title: string }[] = [
        {
            type: null,
            title: translate("showAllWidgetTypeTemplates"),
        },
        {
            type: "CustomerSpecific",
            title: translate("showCustomerSpecificWidgetTypeTemplates"),
        },
        {
            type: WidgetType.CompletedEffects,
            title: translate(WidgetType.CompletedEffects),
        },
        {
            type: WidgetType.RollingForecast,
            title: translate(WidgetType.RollingForecast),
        },
        {
            type: WidgetType.ProcessList,
            title: translate(WidgetType.ProcessList),
        },
        {
            type: WidgetType.CustomBarChart,
            title: translate(WidgetType.CustomBarChart),
        },
        {
            type: WidgetType.FunnelChart,
            title: translate(WidgetType.FunnelChart),
        },
        {
            type: WidgetType.ActivityList,
            title: translate(WidgetType.ActivityList),
        },
        {
            type: WidgetType.IdeaList,
            title: translate(WidgetType.IdeaList),
        },
        {
            type: WidgetType.IdeaMatrix,
            title: translate(WidgetType.IdeaMatrix),
        },
        {
            type: WidgetType.ProcessWhiteSpotMatrix,
            title: translate(WidgetType.ProcessWhiteSpotMatrix),
        },
        {
            type: WidgetType.CommentStream,
            title: translate(WidgetType.CommentStream),
        },
        {
            type: WidgetType.LiveRunUp,
            title: translate(WidgetType.LiveRunUp),
        },
        {
            type: WidgetType.StatusAggregation,
            title: translate(WidgetType.StatusAggregation),
        },
        {
            type: WidgetType.PotentialKPI,
            title: translate(WidgetType.PotentialKPI),
        },
        {
            type: WidgetType.Timeline,
            title: translate(WidgetType.Timeline),
        },
        {
            type: WidgetType.LegacyProjectProgress,
            title: translate(WidgetType.LegacyProjectProgress),
        },
        {
            type: WidgetType.ProjectProgress,
            title: translate(WidgetType.ProjectProgress),
        },
        {
            type: WidgetType.Waterfall,
            title: translate(WidgetType.Waterfall),
        },
    ];

    tabs.sort((a, b) => {
        if (a.type === null && b.type !== null) {
            return -1;
        } else if (b.type === null && a.type !== null) {
            return 1;
        } else if (a.type === "CustomerSpecific" && b.type !== "CustomerSpecific") {
            return -1;
        } else if (b.type === "CustomerSpecific" && a.type !== "CustomerSpecific") {
            return 1;
        } else {
            return a.title.localeCompare(b.title);
        }
    });

    return tabs;
};

const WidgetTemplateDialogContent = ({ widgetTemplates, addDashboardWidget, translate }: WidgetTemplateDialogContentProps) => {
    const [selectedType, setSelectedType] = useState<WidgetTemplateCategory>(null);
    const [searchKey, setSearchKey] = useState("");

    const handleListItemClick = (event: MouseEvent<HTMLDivElement>, type: WidgetTemplateCategory) => {
        setSelectedType(type);
        setSearchKey("");
    };

    const visibleTemplates = widgetTemplates.filter((t) => {
        if (selectedType === null) {
            return true;
        }
        if (selectedType === "CustomerSpecific") {
            return t.clientId != null;
        }
        return t.type === selectedType;
    });

    const tabs = getTabs(translate);

    const visibleTabs = tabs.filter(
        (tab) =>
            tab.type == null ||
            (tab.type === "CustomerSpecific" && widgetTemplates.some((t) => t.clientId != null)) ||
            widgetTemplates.some((template) => template.type == tab.type),
    );

    const sortedTemplates = sortBy(visibleTemplates, [
        ({ type }) => visibleTabs.findIndex((x) => x.type === type),
        ({ name }) => translate(name, { defaultValue: null }) ?? name,
    ]);

    const filteredTemplates =
        searchKey === "" || searchKey.length < 2
            ? sortedTemplates
            : sortedTemplates.filter(({ name }) => translate(name).toLowerCase().indexOf(searchKey.toLowerCase()) > -1);

    return (
        <Stack direction="row" height="100%" paddingBottom={2}>
            <Sidebar spacing={2}>
                <Form role="search">
                    <SearchInput
                        searchKey={searchKey}
                        onChange={setSearchKey}
                        translate={translate}
                        onReset={() => setSearchKey("")}
                        autoFocus
                        fullWidth
                        size="small"
                    />
                </Form>
                <List disablePadding>
                    {visibleTabs.map(({ type, title }) => (
                        <ListItem key={title} disablePadding>
                            <NoWrapListItemButton
                                key={title}
                                onClick={(event) => handleListItemClick(event, type)}
                                selected={type === selectedType}
                            >
                                {title}
                            </NoWrapListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Sidebar>
            <TemplateContainer container>
                <TemplateGrid container item spacing={1}>
                    {filteredTemplates.length > 0 ? (
                        filteredTemplates.map((widgetTemplate) => (
                            <Grid key={widgetTemplate.id} item xs={6}>
                                <WidgetTemplate widgetTemplate={widgetTemplate} addWidget={addDashboardWidget} />
                            </Grid>
                        ))
                    ) : (
                        <Grid item xs={12}>
                            <Typography>{translate(translationKeys.VDLANG_SEARCH_NO_RESULTS, { key: searchKey })}</Typography>
                        </Grid>
                    )}
                </TemplateGrid>
            </TemplateContainer>
        </Stack>
    );
};

export default WidgetTemplateDialogContent;
