import { blueGrey, grey } from "@mui/material/colors";
import { LegacyProjectProgressWidgetConfig, LegendLayout, TooltipLayout } from "api-shared";
import { sortBy } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Bar, CartesianGrid, ComposedChart, Legend, ReferenceLine, Tooltip, XAxis, YAxis } from "recharts";
import ResponsiveContainer from "../../../components/ResponsiveContainer";
import { Option } from "../../../components/input/select/types";
import { useClientFiscalYear } from "../../../domain/client";
import useCurrency from "../../../hooks/useCurrency";
import useTimezone from "../../../hooks/useTimezone";
import { getFiscalYearText } from "../../../lib/fiscal-units";
import { translationKeys } from "../../../translations/main-translations";
import { DeltaLabelList } from "../charts/DeltaLabelList";
import OutlineSvgFilter, { OUTLINE_FILTER_ID } from "../charts/OutlineSvgFilter";
import ShortNoCodeCurrencyLabelList from "../charts/ShortNoCodeCurrencyLabelList";
import { useCurrencyYAxisProps, useDashboardColors, useLegendProps, useMonthXAxisProps, useTooltipProps } from "../charts/hooks";
import { getBarSegments } from "../charts/utils";
import { ExtendableCircleLegend } from "../reporting/ExtendableCircleLegend";
import { BarRectangleItemWithPayloads } from "../types";
import { CalculatedStackKeys } from "../utils";

interface LegacyProjectProgressRunUpChartProps {
    data: { [key: string]: string | number }[];
    xAxis: string;
    groups: Option<string>[];
    showSums?: boolean;
    axisMinValue: number | null;
    axisMaxValue: number | null;
    showReferenceValues?: boolean;
    referenceValues: LegacyProjectProgressWidgetConfig["referenceValues"];
    onOpenDrilldown: (xValue: string, stackingValue: unknown) => void;
}

type BarItem = { [key: string]: string | number };

const LegacyProjectProgressRunUpChart = (props: LegacyProjectProgressRunUpChartProps) => {
    const { data, xAxis, groups, showSums, axisMinValue, axisMaxValue, showReferenceValues, referenceValues, onOpenDrilldown } = props;
    const colors = useDashboardColors();
    const { t } = useTranslation();

    const { formatCurrency } = useCurrency();
    const { formatShortDateTime } = useTimezone();
    const fiscalYearStart = useClientFiscalYear();

    const currencyYAxisProps = useCurrencyYAxisProps(undefined, axisMinValue, axisMaxValue);
    const monthXAxisProps = useMonthXAxisProps();

    const renderReferenceValues = showSums && showReferenceValues && referenceValues !== null;
    const legendPropsSpacing = 2 + Number(renderReferenceValues) * 4;
    const legendProps = useLegendProps(legendPropsSpacing);
    // Pass target to tooltip in order to render the difference to target independently from its visualization in the chart
    const tooltipProps = useTooltipProps({ showDifferenceToTarget: true, tooltipLayout: TooltipLayout.Reversed });

    const groupsInUse = new Set<string>(
        data.flatMap(Object.keys).filter((group) => group !== xAxis && group !== CalculatedStackKeys.TARGET),
    );

    const barSegments = getBarSegments(groups, colors, groupsInUse);

    const sortedData = sortBy(data, xAxis);

    function labelFormatter(label: string) {
        return getFiscalYearText(moment(label), fiscalYearStart, t);
    }

    const handleBarClick = (payload: any, tooltipPayload: any) => {
        const stackingValue = tooltipPayload?.[0]?.dataKey;
        const xValue = payload[xAxis];
        return onOpenDrilldown(xValue, stackingValue);
    };

    const barSegmentKeys = barSegments.map((segment) => segment.key);

    const hasItemsWithTarget = sortedData.some((item: BarItem) => item[CalculatedStackKeys.TARGET] != null);

    return (
        <ResponsiveContainer>
            <ComposedChart data={sortedData} stackOffset="sign">
                <CartesianGrid strokeDasharray="4" vertical={false} />
                <Legend
                    {...legendProps}
                    content={
                        <ExtendableCircleLegend
                            legendLayout={LegendLayout.Reversed}
                            deltaTimestamp={renderReferenceValues ? formatShortDateTime(referenceValues.timestamp) : null}
                        />
                    }
                />
                <Tooltip
                    {...tooltipProps}
                    formatter={(value: number | string) => formatCurrency(value) ?? value}
                    labelFormatter={labelFormatter}
                />

                {barSegments.map(({ key, color, label }, barSegmentIndex, allSegments) => (
                    <Bar
                        name={label}
                        key={key}
                        dataKey={key}
                        stackId="a" // all bars segments should be stacked in the same bar
                        fill={color}
                        stroke="white"
                        strokeWidth={1}
                        legendType="circle"
                        cursor="pointer"
                        onClick={({ payload, tooltipPayload }: BarRectangleItemWithPayloads) => handleBarClick(payload, tooltipPayload)}
                        isAnimationActive={false}
                        xAxisId="default"
                    >
                        {showSums ? <ShortNoCodeCurrencyLabelList stackDataKeys={barSegmentKeys} /> : null}
                        {renderReferenceValues ? (
                            <DeltaLabelList
                                stackDataKeys={barSegmentKeys}
                                axisDataKey={xAxis}
                                referenceValues={referenceValues}
                                isVerticalLayout
                            />
                        ) : null}
                    </Bar>
                ))}

                {/* Render axes last so that other elements (e.g. bars) do not overlay them */}
                <XAxis {...monthXAxisProps} dataKey={xAxis} tickFormatter={labelFormatter} xAxisId="default" />
                <YAxis {...currencyYAxisProps} />

                {hasItemsWithTarget ? (
                    <>
                        <OutlineSvgFilter />

                        {/* Define a second (hidden) bar to be able to display target stacks on top of normal existing bars */}
                        <XAxis {...monthXAxisProps} xAxisId="target" hide />

                        <Bar
                            name={t(translationKeys.VDLANG_DASHBOARDS_TARGET_LABEL)}
                            dataKey={CalculatedStackKeys.TARGET}
                            xAxisId="target"
                            fill="none"
                            stroke={blueGrey[800]}
                            strokeDasharray="4 2"
                            strokeWidth={2}
                            legendType="circle"
                            stackId="1"
                            style={{
                                filter: `url(#${OUTLINE_FILTER_ID})`,
                            }}
                        />
                    </>
                ) : null}

                <ReferenceLine y={0} stroke={grey[600]} xAxisId="default" />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default LegacyProjectProgressRunUpChart;
